import React from 'react';

import { MAIN_DASHBOARD } from '../../utils/appConstants';
import { ALink, KCList, KCListItem } from '../commonControls';

const DashboardHeader = () => (
    <div className="wrapperHeaderA">
        <KCList>
            <KCListItem>
                <ALink href='#' title={MAIN_DASHBOARD} className="dashboardNav active">
                    {MAIN_DASHBOARD}
                </ALink>
            </KCListItem>
        </KCList>
    </div>
)

export default DashboardHeader;