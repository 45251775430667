const todaysDate = new Date();

const REQUEST_TYPE_CHANGE = "REQUEST_TYPE_CHANGE";
const DATE_CHANGE = "DATE_CHANGE";
const SORT_DATA = "SORT_DATA";
const PAGE_CHANGE = "PAGE_CHANGE";
const LOAD_DATA = "LOAD_DATA"
const SHOW_DETAIL = "SHOW_DETAIL"
const HIDE_DETAIL = "HIDE_DETAIL"


export const requestTypeChange = (payload) => ({type: REQUEST_TYPE_CHANGE, payload});
export const dateChange = (payload) =>  ({type: DATE_CHANGE, payload});
export const sortData = (payload) => ({type: SORT_DATA, payload});
export const pageChange = (payload) => ({type: PAGE_CHANGE, payload});
export const loadData = (payload) => ({type: LOAD_DATA, payload});
export const showDetail = (payload) => ({type: SHOW_DETAIL, payload});
export const hideDetail = () => ({type: HIDE_DETAIL});


export const initialState = {
    sortColumn: { path: "dateCreated", order: "desc", sort_color: "#FFFFFF" },
    myRequestList: [],
    startDate: "",
    endDate: "",
    minDate: new Date(todaysDate.setMonth(todaysDate.getMonth() - 24)),
    maxDate: new Date(),
    requestStatus: '',
    currentPage: 1,
    resetPage: false,
    pageSize: 25,
    totalPages: null,
    rowNumber:0,
    loading: true,
    showDetail: false,
    requestDetail: {}
  }


export const myRequestReducer = (state, action) => {
    const {type, payload } = action;
    switch(type){
        case REQUEST_TYPE_CHANGE:
            return { ...state, requestStatus: payload, resetPage:true, loading : true}
        case DATE_CHANGE:
            return { ...state, [payload.field]: payload.value, loading : payload.loading , resetPage:true}
        case SORT_DATA:
            return { ...state, sortColumn: payload, loading : true }
        case PAGE_CHANGE:
            return { ...state, currentPage: payload, loading : true }
        case LOAD_DATA:
            return { ...state, ...payload, loading: false, resetPage: false, rowNumber : (payload.currentPage * state.pageSize) - state.pageSize }
        case SHOW_DETAIL:
            return { ...state, showDetail: true, requestDetail : payload }
        case HIDE_DETAIL:
            return { ...state, showDetail: false, requestDetail : {} }
    }
}