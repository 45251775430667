
    
    //QA:https://api.welbiltdigitalqa.com/
    //dev:https://i9rupwkuj7.execute-api.eu-west-2.amazonaws.com/dev/
    //Ireland Dev:https://pipelineapi.welbiltdigitaldev.com/
    //London Dev:https://api.welbiltdigitaldev.com/
    function getRootURL()
    {
        const hostname = window && window.location && window.location.hostname;
        let backendHost = '';
        if(hostname === 'www.admin.welbiltdigitaldev.com' || hostname === 'admin.welbiltdigitaldev.com') {
            //For Development Environment
            backendHost = 'https://pipelineapi.welbiltdigitaldev.com/';
        } else if (hostname === 'www.admin.welbiltdigitalqa.com' || hostname === 'admin.welbiltdigitalqa.com') {
            //For QA Environment
            backendHost = 'https://api.welbiltdigitalqa.com/';
        } else if (hostname === 'www.admin.welbiltdigitaluat.com' || hostname === 'admin.welbiltdigitaluat.com') {
            //For UAT Environment
            backendHost = 'https://api.welbiltdigitaluat.com/';
        } else if (hostname === 'www.admin.welbiltconnect.com' || hostname === 'admin.welbiltconnect.com') {
            //For Production Environment
            backendHost = 'https://api.welbiltconnect.com/';
        } else if (hostname === 'www.admin.welbiltdigitalintegration.com' || hostname === 'admin.welbiltdigitalintegration.com') {
            //For Integration Environment
            backendHost = 'https://api.welbiltdigitalintegration.com/';
        } else {
            //For Local Environment
            backendHost = 'https://pipelineapi.welbiltdigitaldev.com/';                    
        }
      return backendHost;
    }

    export const BaseService ={
        root : getRootURL(), 
        login : "adminidentityaccessmanagement/admin-identity-access-management/useraccount-login/v1/login",
        loginAuthenticate: "adminidentityaccessmanagement/admin-identity-access-management/useraccount-login/v1/authenticate",
        login_nx_sso: "adminidentityaccessmanagement/admin-identity-access-management/useraccount-login-nx/v1/kc-sso",
    }
    
    export const ApprovalWorkflow ={
        userRequestList: "workflowmanagement/workflow-management/requests",
        userEventPost: "workflowmanagement/workflow-management/events",
        getRequestTypes: "workflowmanagement/workflow-management/requestType",
        dashboardCustomerRegionCountryCount : "admincustomeraccount/admin-mainDashboard-Count/dashboardCounts",
        dashboardLocationUnitCount: "admininventorymanagement/dashboard/v1/dashboardCounts",
        getRequestDetails: "workflowmanagement/workflow-management/request-detail"
    }
        
    export const UserManagement = {
        root:"identityaccessmanagement/identity-access-management/user-management/v1/users",
        geteditUserDetails:"identityaccessmanagement/identity-access-management/user-workflow/v1/users/profile/info",
        requestAccess:"identityaccessmanagement/identity-access-management/user-workflow/v1/users/status-change"
    }
    
    export const userRoleURI = {
        roleBaseAPI:"adminrolemanagement/admin-role-management/v1/roles",
        getRoleList: "adminrolemanagement/admin-role-management/v1/roles/list",
        deleteAPI:"adminrolemanagement/admin-role-management/v1/roles",
        getRolesByCustomer: "identityaccessmanagement/identity-access-management/role-management/v1/roles?customer=",
		getAllFeatures: "adminrolemanagement/admin-role-management/v1/features",
		getFeatures: "identityaccessmanagement/identity-access-management/role-management/v1/roles",
		getRolesBrand: "identityaccessmanagement/identity-access-management/role-management/v1/roles/brands"
    }
    
    export const UserMgmtAddUser = {
        getUserList: "adminidentityaccessmanagement/admin-identity-access-management/user-association/v1/users",
        addUser: "workflowmanagement/workflow-management/events",
        editUser: "workflowmanagement/workflow-management/events",
        deleteUser: "identityaccessmanagement/identity-access-management/user-workflow/v1/users",
        getUserGroup: "admingroupmanagement/admin-group-management/v1/groups/selector/list",
        getCountryCode: "adminidentityaccessmanagement/admin-identity-access-management/user-association/v1/users/countrycode",
        getCountryName: "adminidentityaccessmanagement/admin-identity-access-management/user-association/v1/users/country",
        get_Orgnanization_View: "groupmanagement/group-management/v1/customer-groups/customers/",
        china_sso_url : "adminidentityaccessmanagement/admin-identity-access-management/user-association/v1/nx/pre-login"
    }
    
    export const unitAPI = {
        getSerialNumber: "unitmanagement/unit-management/inventory-management/v1/inventories?",
        addUnit: "unitmanagement/unit-management/unit-registration/v1/units",
        getAllFeatures: "identityaccessmanagement/identity-access-management/role-management/v1/roles/features",
        getBrands: "/subscriptionmanagement/subscription-management/subscriptions/brands",
        getFeatures: "identityaccessmanagement/identity-access-management/role-management/v1/roles",
        getRolesByCustomer: "identityaccessmanagement/identity-access-management/role-management/v1/roles?customer=",
        assignUnitToGroup: "unitmanagement/unit-management/unit-customergroup/v1/assigntounits",
        modelFamilies:"unitmanagement/unit-management/inventory-management/v1/inventories/model_families",
        modelNameFamilies:"admininventorymanagement/dashboard/v1/getModelNameList?brand_id=",        
    }

    export const unitInventory = {
        getModels: "admininventorymanagement/dashboard/v1/getModelList?BRAND_ID=",
        getUnitInventoryList: "admininventorymanagement/dashboard/v1/inventoryList",
        addInventory: "admininventorymanagement/inventory-management/v1/inventory",
        editInventory: "admininventorymanagement/inventory-management/v1/inventory",
        getEditDetails: "admininventorymanagement/dashboard/v1/getSingleInventoryList?serialNo=",
        deleteInventory: "workflowmanagement/workflow-management/deleteInventory",
        downloadUnitTemplate: "admininventorymanagement/dashboard/v1/downloadTemplate",
        getFileUploadLink:"admininventorymanagement/dashboard/v1/getFileKey?filename=",
        uploadFileInformation:"admininventorymanagement/dashboard/v1/fileUpload",
        addBulkInventory: "admininventorymanagement/inventory-management/v1/inventories",
        reviewUnitTemplate: "admininventorymanagement/dashboard/v1/reviewUnitLisInventory?REQUESTID=",        
        eaasManagerList:"admininventorymanagement/dashboard/v1/eaasManagerList",
        registerEaasDevice:"admininventorymanagement/dashboard/v1/registerEaasDevice",
    }
    
    export const UserProfile ={
        getUserProfileDetails : "adminidentityaccessmanagement/admin-identity-access-management/user-association/v1/users/details",
        putUserProfileDetails : "identityaccessmanagement/identity-access-management/user-association/v1/users/profile/info",
        countryCode:            "identityaccessmanagement/identity-access-management/user-association/v1/users/countrycode",
        countryNameAPI:         "identityaccessmanagement/identity-access-management/user-association/v1/users/country",
        getNotificationDetails: "identityaccessmanagement/identity-access-management/user-association/v1/users/preference",   
        get_Orgnanization_View:"groupmanagement/group-management/v1/customer-groups/customers/",
        get_Cust_GroupID:"identityaccessmanagement/identity-access-management/user-association/v1/users/customer-group", 
        userProfile : "admincustomeraccount/admin-customer-account/user-profile-info",
    }
    
    export const unitRegister ={
        getUnitsAssignations:"unitmanagement/unit-management/unit-registration/v1/units",
        activateUnits: "unitmanagement/unit-management/unit-registration/v1/units",
        deleteUnits: "unitmanagement/unit-management/unit-registration/v1/units",
        assigUnits:"unitmanagement/unit-management/unit-customergroup/v1/assigntounits",
        unitCertificate:"unitmanagement/unit-management/unit-provisioning/v1/units",
        unitProvisionState:"unitmanagement/unit-management/unit-provisioning/v1/units",
        timeZoneInfo:"unitmanagement/unit-management/unit-registration/v1/timezones",
    }
    
    export const GroupMgmtApi = {
        getGroupSelectorList: "admingroupmanagement/admin-group-management/v1/groups/selector/list",
        getGroupList: "admingroupmanagement/admin-group-management/v1/groups",
        getGroupTypes: "admingroupmanagement/admin-group-management/v1/groups/group-types",
        getBrandList: "admincustomeraccount/admin-customer-account/brands",
        getCustomerList: "admincustomeraccount/admin-customer-account/customers/list",
        addGroup: "admingroupmanagement/admin-group-management/v1/groups",
        getListOfUsersInGroup: "adminidentityaccessmanagement/admin-identity-access-management/user-association/v1/users/groups",
        groupUserWorkflow: "workflowmanagement/workflow-management/events",
        getCustomerModal: "admincustomeraccount/admin-customer-account/customers/getCustomerDetailsByGroupName",
        getAllRegions:"adminsoftwaremanagement/admin-software-management/v1/software/getAllRegions"
    }

    export const CustomerMgmtApi = {
        getApiKey:"admincustomeraccount/customer-subscription/api-key",
        regenrateApiKey:"admincustomeraccount/customer-subscription/api-key/regenerate",
        getAvailableCustomers: "admincustomeraccount/admin-customer-account/customers",
        getBrandList: "admincustomeraccount/admin-customer-account/brands",
        getFeaturesSubscriptions: "admincustomeraccount/admin-customer-account/customers/featuresubscriptions",
        addCustomer: "workflowmanagement/workflow-management/events",
        editCustomer: "admincustomeraccount/admin-customer-account/customers/details",
        customerBrandListCount:"admincustomeraccount/admin-customer-account/customer-brand",
        customerUnitCount:"admincustomeraccount/admin-customer-account/customer-units",
        userCountForCustomer:"admincustomeraccount/admin-customer-account/total-customers",
        customerUserRoleList:"admincustomeraccount/admin-customer-account/roles",
        customerUserMgmtList:"admincustomeraccount/admin-customer-account/users",
        userActivateUpdate:"admincustomeraccount/admin-customer-account/update-user-status",
        userResetEmail :"admincustomeraccount/admin-customer-account/reset-user-credentials",
        userRoleList :"admincustomeraccount/admin-customer-account/roles-features",
        userProfile : "admincustomeraccount/admin-customer-account/user-profile-info",
        getCertificateUnitList: "admininventorymanagement/dashboard/v1/unitList?CUSTOMERID=",
        deprovisionUnit: "workflowmanagement/workflow-management/provisioning",
        C2CAPIUsageDetails: "admincustomeraccount/customer-subscription/c2c-usage?customerId=",
        userActivateWorkflow : "workflowmanagement/workflow-management/add-user-status-request",
        getSpecificCustomerBrand: "admincustomeraccount/admin-customer-account/allbrands?customer_id=",
        getAllBrandsList: "admincustomeraccount/admin-customer-account/allbrands",
        getEmulationDetails: "identityaccessmanagement/identity-access-management/user-emulation/v1/user",
        resetSession: "admincustomeraccount/user-emulation/reset-session"
    }

    export const BrandMgmtApi = {
        getBrandsList: "admincustomeraccount/admin-brand-management/brand/list",
        createBrand: "workflowmanagement/workflow-management/createBrand",
        getBrandInformation: "admincustomeraccount/admin-mainDashboard-Count/getBrandDetails?requestId="
    }

    export const CustomerOrgMgmtApi = {
        organizaionCategoryDetailsUrl: "admincustomeraccount/admin-customer-account/customer-group-category",
        organizaionLocationTypesUrl: "admincustomeraccount/admin-customer-account/customer-group-types",
        getOrganizationView: "admincustomeraccount/admin-customer-account/customers-groups/customer-list",
        getCountryRegion: "admincustomeraccount/admin-customer-account/aws-region",
        getCustGroupId: "admincustomeraccount/admin-customer-account/customer-group",
        organizaionUnitsDetailsUrl: "admincustomeraccount/admin-customer-account/customer-group-units",
        organizaionSubElementCountUrl: "admincustomeraccount/admin-customer-account/sub-customer-group",
        recentRecordRequest: "groupmanagement/group-management/v1/org-import-export/recent",
        preSignedURL: "groupmanagement/group-management/v1/org-import-export/get-pre-signed-url",
        exportRequest: "groupmanagement/group-management/v1/org-import-export/export",
        preSignedURLUpload: "groupmanagement/group-management/v1/org-import-export/put-pre-signed-url",
    }

    export const SoftwareMgmtApi = {
        getIotAgentSoftwareList: "adminsoftwaremanagement/admin-software-management/v1/software/list?type=",
        getModelsList: "admininventorymanagement/product-management/v1/product/list?brandId=",
        getPresignedFileUploadUrl: "adminsoftwaremanagement/admin-software-management/v1/software/pre-signed-url?fileName=",
        getAvailableSoftwareVersion: "adminsoftwaremanagement/admin-software-management/v1/software/versions?type=",
        getCustomerListByBrand: "admincustomeraccount/admin-brand-management/brand/",
        createSoftware: "workflowmanagement/workflow-management/createSoftware",
    }

    export const ModelMgmtApi = {
        getModelsList: "admincustomeraccount/admin-customer-account/products",
        createUnitModel:"workflowmanagement/workflow-management/create-unit",
        getModelInformation: "admininventorymanagement/product-management/v1/product/modelDetails",
        getModelFamilies: "admininventorymanagement/product-management/v1/product/model-family-list?brandId=",
    }
    

export const managePasswordAPI = {
	passwordChange:"/identityaccessmanagement/identity-access-management/user-management/v1/users/password-change"
}

export const AdminReportsAPI = {
    getUnitsList: "admincustomeraccount/report-mgmt/get-unit-by-brand",
    rawDataReportPost: "admincustomeraccount/report-mgmt/raw-data-report",
    getReportPresigned: "admincustomeraccount/report-mgmt/get-report-signed-url"
}

