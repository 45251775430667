import React from 'react';

const RequestStatusSelector = ({ onChange }) => {
    const dataSource = [
        {text:"Approved", value:"approved"},
        {text:"Pending With Approver 1", value:"pending_approval_1"},
        {text:"Pending With Approver 2", value:"pending_approval_2"},
        {text:"Refer Back", value:"resubmit"},
        {text:"Rejected", value:"rejected"}
    ]

    return (
        <select name="request_type_selector"
            title='Select Request Type'
            className="form-control headerSelect"
            onChange={onChange}
            style={{ width: "250px" }}
        >
            <option value="">Select Status</option>
            {dataSource.map(item=> (<option key={item.value} value={item.value}>{item.text}</option>))}
        </select>
    )
}

export default RequestStatusSelector;