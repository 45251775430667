
export default {
    tokenKey : "token",
    loginInfo : "loginInfo",
    rememberMe : "RememberMe",
    authorization: "Authorization",
    tokenExpireTime :"tokenExpireTime",
    allFeaturesKey: "allFeatures",
    currentFeaturesKey : "currentFeatures",
    userBrands : "brandId",
    loginUserCustomers : "CustomerId"
}