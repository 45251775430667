import { GET_DATA, SET_DATA } from '../../actions/stateActionsData/type';

const initialState = {
    SoftwareModel: {
        type: "",
        softwareId: "",
        fileName: "",
        fileFormat: "",
        brandId: 0,
        brandName: "",
        crcCode: "",
        s3Url: "",
        softwareVersion: "",
        description: "",
        modelNameList: [],
        modelNumberList: [],
        files: {},
        uploadUrl: "",
        customerList: [],
        comment: "",
        softwareName: "",
        fileSize: 0,
    }
}

export default function(state = initialState, action){
    switch(action.type) {
        case GET_DATA:
        return {
            ...state,
            SoftwareModel: action.payload
        }

        case SET_DATA:
        return {
            ...state,
            SoftwareModel: action.payload
        }
        
        default:
        return state;
    }
}