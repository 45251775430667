import React from 'react';
import { Loader } from '../commonControls';
import RequestDate from './RequestDate';
import RequestStatus from './RequestStatus';

const MyRequestGridRow = ({ loading, list, rowNumber, onShowDetail }) => {

    if (!loading && list.length === 0) {
        return (
            <div className='norecordsfoundbluetext'>
                No records found
            </div>
        )
    }

    return (
        <>
            <Loader
              style={{position:"absolute", left:"50%"}}
              size="lg"
              loading={loading}
            />
        
           { list.map((item, i) =>
            <ul className="tBody" key={item.requestId}>
                <li>{rowNumber + i + 1}</li>
                <li>{item.requestType}</li>
                <li><RequestDate date={item.dateCreated} /></li>
                <li><span className="anchorTag" onClick={(e) => onShowDetail(e.target.innerHTML, item)}>
                    <RequestStatus status={item.statusDetails.currentStatus} />
                </span></li>
                <li>{item.comment}</li>
            </ul>

        )}

        </>
        
    )
}

export default MyRequestGridRow;