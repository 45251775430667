import React, { useReducer, useEffect } from "react";
import moment from "moment";
import $ from 'jquery';
import { MyRequestGridRow, RequestStatusSelector, CDatePicker } from ".";
import GoToPagination from "../goToPagination";
import { KCList, KCListItem, Section } from "../commonControls";
import { TableHeader } from "../tableHeader";
import { BaseService, ApprovalWorkflow } from '../../service/api';
import http from '../../service/httpService';

import {
    initialState,
    myRequestReducer,
    requestTypeChange,
    dateChange,
    sortData,
    pageChange,
    loadData,
    showDetail,
    hideDetail
} from '../../reducers/myRequestReducer';

import RequestStatusModal from "../../components/actionspermissions/RequestStatusModal";
import RequestDetail from "./requestDetail/RequestDetail";


const columns = [
    { path: "requestId", label: "#" },
    { path: "requestType", label: "Request Type" },
    { path: "dateCreated", label: "Date Created" },
    { path: "currentStatus", label: "Status" },
    { path: "comment", label: "Approver Comments" }
];

const formatDate = (date) => {
    if (!date) {
        return null
    }
    return moment(new Date(date)).format("YYYY-MM-DD");
}


const RequestList = ({ active }) => {

    const [state, dispatch] = useReducer(myRequestReducer, initialState);

    useEffect(() => {
        document.body.style.background = "#f2f2f2";
        $("html").removeAttr("style");
        if (state.loading) {
            getRequest();
        }

        window.addEventListener('resize', updateDimensions);
    }, [state.loading])

    useEffect(() => {
        updateDimensions();
    })

    const updateDimensions = () => {
        const windowHeight = $(window).height();
        const $tableBody = $('.dashboardTableSrlBar');
        const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }

    const getRequest = async () => {
        let { startDate, endDate } = state;
        const loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        startDate = formatDate(startDate);
        endDate = formatDate(endDate);
        const payload = {
            type: "showRequests",
            useremail: loginUser.email,
        }
        if (state.requestStatus) {
            payload["statusFilter"] = state.requestStatus;
        }
        if (startDate && endDate) {
            payload["startDate"] = startDate;
            payload["endDate"] = endDate;
        }
        const pageNum = state.resetPage ? 1 : state.currentPage;
        let apiUrl = `${BaseService.root}${ApprovalWorkflow.userRequestList}`;
        const pageNumber = `pageNum=${pageNum}`;
        const pageLimit = `pageSize=${state.pageSize}`;
        const sortOrder = `sortOrder=${state.sortColumn.order}&sortBy=${state.sortColumn.path}`
        const url = `${apiUrl}?${pageNumber}&${pageLimit}&${sortOrder}`;
        try {
            const httpResponse = await http({ method: 'post', url, data: payload });
            if (httpResponse && httpResponse.data) {
                const { response, numberOfPages } = httpResponse.data.data;
                dispatch(loadData({ myRequestList: response, totalPages: numberOfPages, currentPage: pageNum }))
            }
        } catch (error) {
            dispatch(loadData({ myRequestList: [], totalPages: 0, currentPage: pageNum }))
        }

    }


    //Reload page only if start date and end date both are selected,
    const dateChangeHandler = (field, value) => {
        const _start_end_date = {
            startDate: state.startDate,
            endDate: state.endDate,
            [field]: value
        };
        let loading = false;
        if (_start_end_date.startDate && _start_end_date.endDate) {
            loading = true;
        }
        dispatch(dateChange({ field, value, loading }))
    }

    const showRequestDetail = async (title, item) => {
        dispatch(showDetail({ ...item, title }))
    }


    return (
        <>
            <Section className="actionsPermissionsHeader">
                <KCList>
                    <KCListItem>
                        <Section className="dateRangeFilter">
                            <CDatePicker label="From" className="fromDateDiv"
                                selected={state.startDate}
                                onChange={(date) => dateChangeHandler('startDate', date)}
                                minDate={state.minDate}
                                maxDate={state.maxDate} />

                            <CDatePicker label="To" className="toDateDiv"
                                selected={state.endDate}
                                onChange={(date) => dateChangeHandler('endDate', date)}
                                minDate={state.minDate}
                                maxDate={state.maxDate} />
                        </Section>
                        <RequestStatusSelector onChange={(e) => dispatch(requestTypeChange(e.target.value))} />
                    </KCListItem>
                    <KCListItem>&nbsp;</KCListItem>
                </KCList>
            </Section>
            <Section className="myRequestTable">
                <TableHeader
                    sortColumn={state.sortColumn}
                    onSort={(sortColumn) => dispatch(sortData(sortColumn))}
                    columns={columns}
                />
            </Section>
            <Section className="dashboardTableSrlBar">
                <Section className="myRequestTable">
                    <MyRequestGridRow
                        loading={state.loading}
                        list={state.myRequestList}
                        rowNumber={state.rowNumber}
                        onShowDetail={showRequestDetail} />
                </Section>
            </Section>
            <GoToPagination
                totalPageNumber={state.totalPages}
                pageNumber={state.currentPage}
                navigateTo={(p) => dispatch(pageChange(p))}
            />
            <RequestDetail 
            open={state.showDetail}
            onClose={() => dispatch(hideDetail())}
            requestObject={state.requestDetail}
            editable
            />

           
            {/* <RequestStatusModal
                open={state.showDetail}
                onClose={() => dispatch(hideDetail())}
                requestObject={state.requestDetail}
                editUserDetails={(e) => () => { }}
                downloadUnitInventory={(e) => () => { }}
                onSuccessModel={() => dispatch(hideDetail())}
            /> */}
        </>
    )
}

export default RequestList;
