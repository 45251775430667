import React, { useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import $ from 'jquery';

const style ={
    width: "100%",
    display: "flex",
    textAlign: "center",
    flex: 1,
    marginTop: "15px",
    justifyContent: "center",
    alignItems: "center"
  }

export const Section = ({children, ...rest}) => <div {...rest}>{children}</div>
export const KCList = ({children, ...rest}) => <ul {...rest}> {children} </ul>
export const KCListItem = ({children, ...rest}) => <li {...rest}> {children} </li>
export const ALink = ({children, ...rest}) => <a {...rest} onClick={(e)=> e.preventDefault()} >{children}</a>
export const ScrollableSection = ({children, bgColor}) =>{

    useEffect(()=>{
        document.body.style.background = bgColor || "#f2f2f2";
        window.addEventListener('resize', pageWrapperMinH());
    },[]);

    const pageWrapperMinH = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
    }

    return(
        <div className='pageScrollbar'>{children}</div>
    )
}

export const Loader = ({size, loading, ...rest}) => {
    const sizes = {sm:7, md:10, lg:12}
    return(
        <div {...rest}>
            <BeatLoader color='#333333' size={sizes[size]} loading={loading}/>
        </div>
    )
} 

export const PageLoader = ({size=10, loading, color = "#333333" }) =>{
    const sizes = {sm:7, md:10, lg:12}
    return(
        <BeatLoader
                 size={sizes[size]}
                  className='mainMenuLoader'
                  margin={2}
                  color={color}
                  loading={loading}
                  speedMultiplier={1}
                  aria-label="Loading Spinner"
                  data-testid="loader"
        />
    )
}

export const LoaderText = ({loading }) => {
    return(
        <>
            {loading ? <span>Loading.....</span>:""}
        </>
    )
} 

