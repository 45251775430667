import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import { MY_REQUEST, PENDING_APPROVAL } from '../../utils/appConstants';


const DashboardNavigationTab = ({onTabChange}) => {
    const [active, setActive ] = useState("MyRequest");

    const handleTabChange = (tab) =>{
        setActive(tab);
        onTabChange(tab);
    }
    return (
        <div className="wrapperHeaderA wrapperHeaderAA">
            <ul>
                <li>
                    <Link
                        to="#"
                        title={MY_REQUEST}
                        className={ active==="MyRequest" ? "active":""}
                        onClick={() => handleTabChange("MyRequest")}
                    >
                        {MY_REQUEST}
                    </Link>
                </li>
                <li>
                    <Link
                        to="#"
                        title={PENDING_APPROVAL}
                        className={ active==="MyApproval" ? "active":""}
                        onClick={() => handleTabChange("MyApproval")}
                    >
                        {PENDING_APPROVAL}
                    </Link>

                </li>
                <li>&nbsp;</li>
            </ul>
        </div>
    )
}

export default DashboardNavigationTab;