import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import { DATE_FORMAT_VALUE, REVIEW_UNIT_MESSAGE, REVIEW_UNIT_INVENTORY } from '../../../utils/appConstants';
import moment from 'moment';
import { BaseService, ApprovalWorkflow, unitInventory } from '../../../service/api';
import http from '../../../service/httpService';
import { useHistory } from 'react-router-dom';
import { KCList, KCListItem, Loader, LoaderText, Section } from '../../commonControls';

const styles = { fontFamily: "sans-serif", textAlign: "center" };


const RequestStatusModal = ({ open, requestObject, onClose, editable = false }) => {

  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setDetail(null);
      getRequestDetail();
    }
  }, [open])

  const getRequestDetail = async () => {
    setLoading(true);
    const { requestId } = requestObject;
    const payload = {
      "request_id": requestId,
      "type": "showRequests",
    }
    try {
      const response = await http({
        method: 'post',
        url: `${BaseService.root}${ApprovalWorkflow.getRequestDetails}`,
        data: payload
      });
      setDetail(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <div style={styles}>
      <Modal show={open} onHide={onClose} animation={false} centered size='lg'>
        <Modal.Header>
          <Modal.Title>{requestObject.title}</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" onClick={onClose}>&nbsp;</button>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <LoaderText loading={loading}/>
          {detail && getDetailView(detail, editable)}
        </Modal.Body>
      </Modal>
    </div>
  );
}

//Fuction dinamically return Details component based on request type
const getDetailView = (detail, editable) => {
  
  if (!(detail && detail.statusDetails && detail.statusDetails.requestDetails))
    return <h6 className="norecordsfoundbluetext">No records found.</h6>;

  switch (detail.requestType) {
    case "Add User":
    case "Edit User":
      return <UserRequestDetail detail={detail} editable={editable} />
    case "Add Unit Inventory":
    case "Edit Unit Inventory":
    case "Delete Unit Inventory":
    case "Deprovisioning Unit":
      return <InventoryRequestDetail detail={detail} editable={editable}/>
    case "Add Bulk Unit Inventory":
    case "Edit Unit Bulk Inventory":
    case "Delete Unit Bulk Inventory":
      return <BulkInventoryRequestDetail detail={detail} editable={editable}/>
    case "Add Brand":
    case "Edit Brand":
    case "Delete Brand":
      return <BrandRequestDetail detail={detail} editable={editable}/>
    case "Add Model":
    case "Edit Model":
    case "Delete Model":
      return <ModelRequestDetail detail={detail} editable={editable}/>
    case "Activate Customer User":
    case "Deactivate Customer User":
      return <ActivateCustomerRequestDetail detail={detail} editable={editable}/>
    case "Add IOT Software":
    case "Edit IOT Software":
    case "Add Controller Software":
    case "Edit Controller Software":
    case "Delete IOT Software":
      return <SoftwareRequestDetail detail={detail} editable={editable}/>
    case "Add Customer":
    case "Edit Customer":
    case "Delete Customer":
      return <CustomerRequestDetail detail={detail} editable={editable}/>
    default:
      return null;
  }
}

//Request Detail Components

const SoftwareRequestDetail = ({ detail, editable }) => {
 const {requestDetails:requestDetail , events, currentStatus} = detail.statusDetails;
  return (
    <>
    <div class="pendingApprovalsTableExp">
      <DetailField label="Brand Name" text={requestDetail.brandName || 'NA'} />
      <DetailField label="Model Family" text={arrayToText(requestDetail.modelFamilyNames, item => item)} />
      <DetailField label="Model Name" text={arrayToText(requestDetail.modelNames, item => item)} />
      <DetailField label="Software Update" text={requestDetail.fileName || 'NA'} />
      <DetailField label="Software Version" text={requestDetail.version || 'NA'} />
      <DetailField label="Release Notes" text={requestDetail.description || 'NA'} />
      <DetailField label="Customer Group Name" text={arrayToText(requestDetail.customersGroupDetails, item => item.short_name ? item.name + "(" + item.short_name + ")" : item.name)} />
      <DetailField label="Comment" text={requestDetail.comment || 'NA'} />
    </div>
    <ApprovalHistory events={events} />
    {editable && <EditButton onClick={() => { }} requestType={detail.requestType} currentStatus={currentStatus} />}
    </>
  )
}

const ActivateCustomerRequestDetail = ({ detail, editable }) => {
  const {requestDetails:requestDetail , events, currentStatus} = detail.statusDetails;
  const history = useHistory();

  const onEditCustomerRequest = () => {
    if(['Activate Customer User','Deactivate Customer User'].includes(detail.requestType)){
      history.push({ 
        pathname:'/customerUserMgmtList',
        search: `cid=${parseInt(requestDetail.customer_id)}`
      })
    }
  }

  return (
    <>
      <Section className="pendingApprovalsTableExp">
        <DetailField label="Customer Name" text={requestDetail.customerName || "NA"} textClass='disabled' />
        <DetailField label="User Name" text={requestDetail.name || "NA"} textClass='disabled' />
        <DetailField label="User Role" text={requestDetail.roleName || "NA"} textClass='disabled' />
        <DetailField label="Email" text={requestDetail.username || "NA"} textClass='disabled' />
        <DetailField label="Phone Number" text={`${requestDetail.country_code} ${requestDetail.phone_number}`} textClass='disabled' />
        <DetailField label="Activation Status" text={requestDetail.is_active === 0 ? "deactivated" : "activated"} />
      </Section>
      <ApprovalHistory events={events} />
    {editable && <EditButton onClick={onEditCustomerRequest} requestType={detail.requestType} currentStatus={currentStatus}/>}
    </>
  )
}

const CustomerRequestDetail = ({ detail, editable }) => {
  const {requestDetails:requestDetail , events, currentStatus} = detail.statusDetails;
  const history = useHistory();

  const onCustomerEdit = () =>{
    if(['Add Customer','Edit Customer'].includes(detail.requestType)){
      let searchQuery = `customerName=${requestDetail.customerName}&status=pending`;
      if (requestDetail.admin_email){
        searchQuery+= `&adminEmail=${requestDetail.admin_email}`;
      }
      history.push({
        pathname: '/editCustomer',
        search: searchQuery
      }) 
    }
  }
  return (
    <>
    <Section className="pendingApprovalsTableExp">
      <DetailField label="Customer Admin Name" text={requestDetail.admin_user_name || 'NA'} />
      <DetailField label="Customer Name" text={requestDetail.customerName || 'NA'} />
      <DetailField label="Location" text={<LocationDetail requestDetail={requestDetail} />} />
      <DetailField label="Start Date" text={<SubscriptionDate date={requestDetail.subsCriptionStartDate} />} />
      <DetailField label="End Date" text={<SubscriptionDate date={requestDetail.subsCriptionEndDate} />} />
      <DetailField label="KC3.0 Offering" text={arrayToText(requestDetail.subscribedFeatures[0].features, item => item.feature_name)} />
      <DetailField label="C2C API Offering" text={arrayToText(requestDetail.subscribedFeatures[1].features, item => item.feature_name)} />
    </Section>
    <ApprovalHistory events={events} />
    {editable && <EditButton onClick={onCustomerEdit} requestType={detail.requestType} currentStatus={currentStatus} />}
    </>
    
  )
}

//Add User or Edit User 
const UserRequestDetail = ({ detail, editable }) => {
  console.log("Detail - ", detail);
  const { requestDetails: requestDetail, events, currentStatus } = detail.statusDetails;
  const history = useHistory();

  const onEditUser = () => {
    localStorage.setItem("customerName", requestDetail.customerName);
    localStorage.setItem("userEmail", requestDetail.email);
    localStorage.setItem("userroleName", requestDetail.roleName);
    localStorage.setItem("userBaseCountry", requestDetail.base_country);
    history.push({
      pathname: '/adminEditUser',
      name: requestDetail.customerName,
      email: requestDetail.email,
      roleName: requestDetail.roleName,
      baseCountry: requestDetail.base_country
    })
  }

  return (
    <>
      <Section className="pendingApprovalsTableExp">
        <DetailField label="User Name" text={requestDetail.username} />
        <DetailField label="User Role" text={requestDetail.roleName} />
        <DetailField label="Email" text={requestDetail.email} />
        <DetailField label="Phone Number" text={`${requestDetail.country_code} ${requestDetail.phoneNumber}`} />
        <DetailField label="Country" text={requestDetail.country} />
        <DetailField label="Assigned Customer Groups" text={_.join(_.map(requestDetail.groups, item => item.groupName), ', ')} />
        <DetailField label="Comment" text={requestDetail.comment} />
        <DetailField label="Created By" text={<CreatedBy email={requestDetail.requesterEmail} role={requestDetail.requesterRoleName} />} />
      </Section>
      <ApprovalHistory events={events} />
      {editable && <EditButton onClick={onEditUser} requestType={detail.requestType} currentStatus={currentStatus}/>}
    </>
  )
}

//Add Unit Inventory, Edit Unit Inventory, Delete Unit Inventory, Deprovisioning Unit
const InventoryRequestDetail = ({ detail, editable }) => {
  const {requestDetails:requestDetail , events, currentStatus} = detail.statusDetails;
  const history = useHistory();

  const onInventoryEdit = () => {
    let url = "";
    let search = "";
    if (['Add Unit Inventory', 'Edit Unit Inventory'].includes(detail.requestType)) {
      const unitSerialNo = requestDetail.SERIALNO;
      const unitBrandId = requestDetail.BRANDID;
      localStorage.setItem("unitSerialNo", unitSerialNo);
      localStorage.setItem("unitBrandId", unitBrandId);
      url = `/editInventory/${unitSerialNo}/${unitBrandId}`
    } else if (['Delete Unit Inventory', 'Add Unit Bulk Inventory'].includes(detail.requestType)) {
      url = '/inventoryManagement';
    } else if (['Deprovisioning Unit'].includes(detail.requestType)) {
      url = '/customerCertificateMgmt'
      search = `cid=${requestDetail.CUSTOMER_ID}`
    }
    history.push({ pathname: url, search: search })
  }
  return (
    <>
      <Section className="inventoryModalTable">
        <KCList className="tHead">
          <KCListItem>#</KCListItem>
          <KCListItem>Serial Number</KCListItem>
          <KCListItem>Brand</KCListItem>
          <KCListItem>Model Name</KCListItem>
          <KCListItem>Registration Code</KCListItem>
        </KCList>
        <KCList className="tBody">
          <KCListItem>1</KCListItem>
          <KCListItem>{requestDetail.SERIALNO || "NA"}</KCListItem>
          <KCListItem>{requestDetail.BRANDNAME || "NA"}</KCListItem>
          <KCListItem>{requestDetail.MODELNAME || "NA"}</KCListItem>
          <KCListItem>{requestDetail.REGISTRATION_CODE || "NA"}</KCListItem>
        </KCList>
      </Section>
      <Section className="buttonAlignCenter">
        <p>{REVIEW_UNIT_MESSAGE}</p>
        <button className="btn btn-dark" type="button" onClick={() => downloadInventory(requestDetail)}>{REVIEW_UNIT_INVENTORY}</button>
      </Section>
      <ApprovalHistory events={events} />
      {editable && <EditButton onClick={onInventoryEdit} requestType={detail.requestType} currentStatus={currentStatus}/>}
    </>
  )
}
//Add Bulk Unit Inventory, Edit Unit Bulk Inventory, Delete Unit Bulk Inventory
const BulkInventoryRequestDetail = ({ detail, editable }) => {
  const {requestDetails:requestDetail , events, currentStatus} = detail.statusDetails;

  return (
    <>
      <Section className="inventoryModalTable">
        <KCList className="tHead">
          <KCListItem>#</KCListItem>
          <KCListItem>Serial Number</KCListItem>
          <KCListItem>Brand</KCListItem>
          <KCListItem>Model Name</KCListItem>
          <KCListItem>Registration Code</KCListItem>
        </KCList>
        {requestDetail.map((item, i) =>
        (
          <KCList className="tBody" key={i}>
            <KCListItem>{i + 1}</KCListItem>
            <KCListItem>{item.SERIALNO || "NA"}</KCListItem>
            <KCListItem>{item.BRANDNAME || "NA"}</KCListItem>
            <KCListItem>{item.MODELNAME || "NA"}</KCListItem>
            <KCListItem>{item.REGISTRATION_CODE || "NA"}</KCListItem>
          </KCList>
        )
        )}
      </Section>
      {requestDetail.length >= 5 &&
        <Section className="buttonAlignCenter">
          <p>{REVIEW_UNIT_MESSAGE}</p>
          <button
            className="btn btn-dark"
            type="button"
            onClick={() => downloadInventory(requestDetail)}>
            {REVIEW_UNIT_INVENTORY}
          </button>
        </Section>}
      <ApprovalHistory events={events} />
      {editable && <EditButton onClick={() => { }} requestType={detail.requestType} currentStatus={currentStatus}/>}
    </>
  )

}

const BrandRequestDetail = ({ detail, editable }) => {
  const {requestDetails:requestDetail , events, currentStatus} = detail.statusDetails;

  const history = useHistory();

  const onBrandEdit = () =>{
    if(['Add Brand'].includes(detail.requestType)){
      const requestId = detail.requestId;
      history.push({
        pathname: '/editBrand',
        search: `requestID=${requestId}`
      })
    }
  }

  return (
    <>
    <Section className="pendingApprovalsTableExp">
      <DetailField label="Brand Name" text={requestDetail.brandName || 'NA'} />
      <DetailField label="Brand Logo" text={<LogoImage className="brandImg" logoUrl={requestDetail.logoUrl} />} />
      <DetailField label="Comment" text={requestDetail.comment || 'NA'} />
    </Section>
    <ApprovalHistory events={events} />
    {editable && <EditButton onClick={onBrandEdit} requestType={detail.requestType} currentStatus={currentStatus}/>}
    </>
  )
}

const ModelRequestDetail = ({ detail, editable }) => {
  const {requestDetails:requestDetail , events, currentStatus} = detail.statusDetails;
  const history = useHistory();

  
  const onModelEdit = () =>{
    if(['Add Model'].includes(detail.requestType)){
      const requestId = detail.requestId;
      const modelBrandName = requestDetail.brandName;
      history.push({
        pathname: '/editModel',
        search: `requestID=${requestId}&brandName=${modelBrandName}`
      })
    }
  }
  return (
    <>
    <Section className="pendingApprovalsTableExp">
      <DetailField label="Brand Name" text={requestDetail.brandName} />
      <DetailField label="Brand Type" text={requestDetail.brandType} />
      <DetailField label="Model Family" text={requestDetail.modelFamilyName} />
      <DetailField label="Model Name" text={requestDetail.modelName} />
      <DetailField label="Unit Logo" text={<LogoImage className="modelImg" logoUrl={requestDetail.logoUrl} />} />
      <DetailField label="Comment" text={requestDetail.comment} />
    </Section>
    <ApprovalHistory events={events} />
    {editable && <EditButton onClick={onModelEdit} requestType={detail.requestType} currentStatus={currentStatus}/>}
    </>
    
  )
}


//Utility Components
const SubscriptionDate = ({ date }) => (<>{date ? moment(date).format(DATE_FORMAT_VALUE) : ""}</>)


const LocationDetail = ({ requestDetail }) => (
  <>
    {`${requestDetail.streetNumber}, ${requestDetail.streetName}, ${requestDetail.city ? requestDetail.city + ',' : ''}  ${requestDetail.zip_code ? requestDetail.zip_code + ',' : ''} ${requestDetail.country}`}
  </>
)

const LogoImage = ({ logoUrl, ...rest }) => (
  <span {...rest} >
    {logoUrl ? <img src={logoUrl} alt="" /> : ""}
  </span>
)

const DetailField = ({ label, text, labelClass = "", textClass = "" }) => (
  <ul>
    <li className={labelClass}>{label}</li>
    <li className={textClass}>{text}</li>
  </ul>
)

const EditButton = ({ requestType, currentStatus, onClick }) => {
  if(currentStatus!=="Re-Submit"){
    return "";
  }
  let label = requestType.replace('Add', "Edit");
  switch (label) {
    case "Deprovisioning Unit":
      label = "Certificate Management List"
      break;
    case "Activate Customer User":
    case "Deactivate Customer User":
      label = "Customer User Management List";
      break;
    default:
      break;
  }
  return (
    <div className="modal-footer textAlignCenter">
      <button className="btn btn-dark" type="button" onClick={onClick}>
        {label}
      </button>
    </div>
  )
}

const CreatedBy = ({ email, role }) => (<> {email}<span>{role}</span> </>)

const ApprovalHistory = ({ events }) => {
  const columns = ['Level', 'User', 'Status', 'Comments', 'Last Action On'];
  return (
    <div className="myRequestTableExp">
      <ul className="tHead">
        {columns.map(c => (<li key={c}>{c}</li>))}
      </ul>
      {events.map((item, i) =>
        <ul className="tBody" key={i}>
          <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
          <li>{item.email}</li>
          <li>{item.status}</li>
          <li>{item.comment}</li>
          <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
        </ul>)}
    </div>
  )
}

//Utility Functions
const downloadInventory = async (requestDetail) => {
  console.log(requestDetail)
  if (requestDetail) {
    const response = await http({
      method: 'get',
      url: `${BaseService.root}${unitInventory.reviewUnitTemplate}${requestDetail.ID}`,
      data: {}
    });
    if (response.data && response.data.body.fileUrl) {
      window.location = response.data.body.fileUrl;
    }
  }
}

const arrayToText = (arrayList, selector) => {
  if (arrayList && arrayList.length) {
    return arrayList.map(selector).join(', ');
  } else {
    return "NA"
  }
}

export default RequestStatusModal