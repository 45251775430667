
export default {
    UnAuthorized :"You are not authorized to view this page",
    SessionExpire: "Your session has expired. Please login again."
}

export const staticNotificationTypeData = [
  {
    Id: 960,
    preference_name: "Errors",
    preference_value: "Text,Email",
    isTextChecked: false,
    isEmailChecked: false
  },
  {
    Id: 961,
    preference_name: "Non-critical Errors",
    preference_value: "Text,Email",
    isTextChecked: false,
    isEmailChecked: false
  },
  {
    Id: 962,
    preference_name: "Information",
    preference_value: "Text,Email",
	isTextChecked: false,
    isEmailChecked: false
  }
];
