import React, {useRef} from 'react';
import { Loader } from '../commonControls';
import RequestDate from './RequestDate';

const MyApprovalGridRow = ({ loading, list, rowNumber, onShowDetail , onItemSelect }) => {

    // const typeRef = useRef();

    if (!loading && list.length === 0) {
        return (
            <div className='norecordsfoundbluetext'>
                No records found
            </div>
        )
    }

    return (
        <>
         <Loader
            style={{position:"absolute", left:"50%"}}
              size="lg"
              loading={loading}
            />
        {list.map((item, i) =>
            <ul className="tBody" key={i}>
            <li>{rowNumber + i + 1}</li>
            <li>
                <span 
                    className="anchorTag"
                    onClick={()=>onShowDetail(item)}
                    >
                        {item.requestType ? item.requestType.type : "" }
                </span>
            </li>
            <li><RequestDate date={item.dateCreated} /></li>
            <li>{item.comment}</li>
            <li>{item.createdByEmail}</li>
            <li>
                <div className="customCheckbox customCheckboxWOlabel">
                    <input id={`checkbox_${i}`} type="checkbox" value={item.checked}
                        checked={item.checked}
                        onChange={() => onItemSelect(item)} />
                    <label htmlFor={`checkbox_${i}`}></label>
                </div>
            </li>
        </ul> 
        )}
        </>
    )
}


export default MyApprovalGridRow;