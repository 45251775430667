export default {
    filterKey: "dashBoardFilters",
    mediaFilters: "mediaFilters",
    softwareFilters: "softwareFilters",
    menuFilters: "menuFilters",
    basecountryHeaderName: "basecountry"
}

export const BRAND_ID_VALUE = {
    CREM: 4,
    MERCO: 2,
    MERRYCHEF: 1,
    CONVOTHERM: 6,
    FRYMASTER: 3,
};

//User Story 63364: Remove brand group dependency in Admin Portal(Technical)
// let brands = JSON.parse(localStorage.getItem('brandId'));
let brands = [];
export let CUSTOMER_ID = localStorage.getItem("customerId") || 1;
export let BASECOUNTRY_ID = localStorage.getItem("basecountry") || "";
export let ACCESSCOUNTRY_ID = localStorage.getItem("accesscountry") || "";
export let BRAND_ID = brands && brands.length>0 ? brands.join(',') : [].join(',');
export const BRANDNAME = localStorage.getItem("brandName");
export let UNIT_BRAND_ID = localStorage.getItem('unitBrandId');
export let UNIT_ID = localStorage.getItem('unitID');
export let PRODUCT_NAME = localStorage.getItem('modelName');
export const TIMER_DOUBLE_TAPS_WIDGET_ID = 1;
export const NO_OF_HOLDING_CYCLES_WIDGET_ID = 2;
export const AVERAGE_HOLDING_CYCLES_WIDGET_ID = 3;
export const TOTAL_ON_TIME_WIDGET_ID = 5;
export const TOTAL_ON_PERIOD_TIME_WIDGET_ID = 6;
export const TOTAL_OFF_TIME_WIDGET_ID = 7;
export const ON_TIME_PERCENTAGE_WIDGET_ID = 8;
export const RESET_CYCLE_WIDGET_ID = 9;
export const OVERALL_PAN_UTILIZATION_WIDGET_ID = 20;
export const TOTAL_TRAY_WIDGET_ID = 30;
export const AVG_TRAYS_WIDGET_ID = 31;
export const CONVO_TOP_TEN_PRODUCT_WIDGET_ID = 37;
export const setBrandId = (brandids) =>{
    BRAND_ID = brandids;
};
export const RESTRICTED_CHAR = "%^&./?\:*<>|";
export const restrictedCharAvl = (itemOfRestChar,typedvalue) => { 
    return typedvalue.includes(itemOfRestChar);
}
export const setunitBrandId = (unitbrandids,unitid,modelname) =>{
    UNIT_BRAND_ID = unitbrandids;
    UNIT_ID = unitid;
    PRODUCT_NAME = modelname;
};
export const setcustomerId = (customerids) =>{
    CUSTOMER_ID = customerids;
};
export const setbasecountryId = (basecountryids) =>{
    BASECOUNTRY_ID = basecountryids;
};
export const setaccessCountryId = (accesscountryids) =>{
    ACCESSCOUNTRY_ID = accesscountryids;
}

var quarterHours = ["00", "30"];
var thirtyMntstimesIntervel = ["12:00 AM", "12:30 AM",];
for(var i = 1; i < 12; i++){
    for(var j = 0; j < 2; j++){				 
        thirtyMntstimesIntervel.push(i + ":" + quarterHours[j]+" AM");
    }
        
}
thirtyMntstimesIntervel.push("12:00 PM", "12:30 PM",);
for(var i = 1; i < 12; i++){
    for(var j = 0; j < 2; j++){				
        thirtyMntstimesIntervel.push(i + ":" + quarterHours[j]+" PM");
    }
}
export let thirty_Mnts_Interval = thirtyMntstimesIntervel;

export const NO_RESULT_MSG = "No Results";
export const MEDIA_NO_RESULT_MSG = "Media management feature not available.";
export const DATE_FORMAT_VALUE = "MMM DD, YYYY h:mm A";
export const MY_REQUEST = "My Requests";
export const PENDING_APPROVAL = "My Approvals";
export const MAIN_DASHBOARD = "Main Dashboard";
export const UNIT_INVENTORY_LIST = "Unit Inventory List";
export const REVIEW_UNIT_INVENTORY = "Review Unit Inventory";
export const REVIEW_UNIT_MESSAGE = "Click on the button below to review the complete list of units.";
/**  Header Title Name**/
export const CERTIFICATE_MANAGEMENT  = "Certificate Management";


/** Header Title ClassName **/
export const CERTIFICATE_MANAGEMENT_CLASSNAME  = "CertificateManagementNav";

/** Placeholder Text**/
export const SEARCH_CUSTOMER_NAME = "Search Customer Name";
export const SEARCH_USER_NAME = "Search User Name";
export const SEARCH_GROUP_NAME = "Search Group Name";
export const SEARCH_MODEL_NAME = "Search Model Name";
export const SEARCH_BRAND_NAME = "Search Brand Name";
export const SEARCH_NAME = "Search Name";
export const SEARCH_USER_ROLE = "Search User Role";
export const SEARCH_CUSTOMER = "Search Customer";
export const INVENTORY_SUCCESS_MSG = "Inventory created sucessfully.";
export const SERVICE_ERROR_MSG = "Service error, Please try later.";
export const INVENTORY_DELETE_MSG = "Inventory Deleted Successfully.";
export const NO_RECORDS_FOUND_MSG = "No records found.";
