import axios from "axios";
import {BaseService} from '../service/api';
import authConstants from '../utils/authConstants';
import broadCastTokenEvent from '../events/tokenAuthrizer';
// import logger from "./loggerService";
// import { toast } from "react-toastify";
 
const { tokenKey, authorization, loginInfo}  = authConstants;

const axionInstance = axios.create({
  baseURL: BaseService.root
  //baseURL : 'https://api.welbiltdigitaldev.com/'
});

/**
 * Before API request start 
 * Set Headers configurations
 * Set Authorization Token Header on each request
*/
axionInstance.interceptors.request.use(config=>{
  let { customHeaders } = config;
  let token = config.headers[authorization];
  if(config.method.toLowerCase()==="get"){
    if(isIEVesrion()){
      config.url =config.url.indexOf("?") > -1 ? `${config.url}&&refreshCache=${new Date().getTime()}` : `${config.url}?refreshCache=${new Date().getTime()}`;
    }
  }
  //Set Customer Headers
  if(customHeaders!=null){
    for(var key in customHeaders){
      config.headers[key]= customHeaders[key];
    }
  }
  config.headers[authorization] = token ? token :  localStorage.getItem(tokenKey);
  return config;
});

/**
 * Transform API response
 * Reset Authorization Token 
 * Retry AJAX call after Athorization failed
*/
axionInstance.interceptors.response.use(

response =>{
  return Promise.resolve(response);
}, 
  
error => {

  if(error && !error.response && !error.config.__isRetry){
    broadCastTokenEvent.IsBroadCastJwtTokenExpired();
    error.config.__isRetry = true;
  }
    
  // return getNewToken()
  // debugger;
  //const isAuthorizationError = error.response && (error.response.status ==400 || error.response.status ==401 || error.response.status ==403 || error.response.status ==500 || error.response ==undefined);
  let isAuthorizationError=false;
  if (error.response !== undefined)
  {
    isAuthorizationError = (error.response.status ===400 || error.response.status ===401 || error.response.status ===403 || error.response.status ===500);
  }
  
  let token = localStorage.getItem(tokenKey);
  
  if (!token && error.config && !error.config.__isRetry) {
    console.log('ACCESS TOKEN EXPIRED!');
    return getNewToken()
    .then(token => {
        console.log('TOKEN RECEIVED', token);
        error.config.__isRetry = true;
        error.config.headers[authorization] =  token;
        return axionInstance(error.config);
    })
    .catch(function () {
        console.log('Refresh login error: ', error);
        return Promise.reject(error);
    });
  }
  else{
    return Promise.reject(error);
  }

  // Log Errors 
  // if (!expectedError) {
  //   logger.log(error);
  //   toast.error("An unexpected error occurrred.");
  // }
});

/**
 * Get New Token
 * Through relogin / refresh token
 */
function getNewToken(){

  let url = `${BaseService.root}` + `${BaseService.login}`;
  
  let loginUser =   JSON.parse(localStorage.getItem(loginInfo));

  

  return new Promise((resolve, reject)=>{
    var decodeVal = new Buffer(loginUser.password, 'base64')
    var encodePassword = decodeVal.toString();
    const body = {
			"username": loginUser.username,
      			"password": encodePassword
		};
    axios({
      url: url,
      method: 'POST',
      data: body,
      headers : {
        "content-type" : "application/json" 
      }
    }).then(response =>{
      let { data } = response;
      let { body } = data;
      // document.cookie =`${tokenKey}=${body.idToken}`; 
      localStorage.setItem(tokenKey, body.idToken);
      resolve(body.idToken);
    }).catch(err =>{
      reject("Authorization Error");
    });
  });
}

axionInstance.setJwt = function(token){
  axionInstance.defaults.headers[authorization] = token;
};

function isIEVesrion(){
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older 
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  // var edge = ua.indexOf('Edge/');
  // if (edge > 0) {
  //   // Edge (IE 12+)
  //   return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  // }

  // other browser
  return false;
}


export default axionInstance;

