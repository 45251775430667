
import React, { Component } from 'react';

export class TableHeader extends Component {
    state = {}

    raiseSort = path => {
        const sortColumn = { ...this.props.sortColumn };
        if (sortColumn.path === path)
            sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
        else {
            sortColumn.path = path;
            sortColumn.order = "asc";
        }

        this.props.onSort(sortColumn)
    };

    checkBoxChange = (e) => {
        this.props.selectAllChange(e);
    };

    sortIcon = column => {
        const { sortColumn } = this.props;

        if (column.path !== sortColumn.path) return null;
        if (sortColumn.order) {
            if (sortColumn.order === "asc") return <i className="-sort-asc"></i>;

            return <i className="-sort-desc"></i>
        }
    }

    render() {

        let { sortColumn, columns } = { ...this.props };
        let iconStyle = { boxShadow: "none" };
        if (sortColumn.order) {
            iconStyle = sortColumn.sort_color ? { boxShadow: `inset 0 -1px 0 0 ${sortColumn.sort_color}` } : iconStyle;
        }

        columns.forEach(c => {
            if (c.path) {
                if (c.path === sortColumn.path) {
                    c.iconStyle = iconStyle;
                }
                else {
                    c.iconStyle = { boxShadow: "none" };
                }
            }
        });
        return (
            <React.Fragment>
                    <ul className="tHead">
                        {
                            columns.map((column, i) => {

                                if (column.path)
                                    return <li key={'key_ths_sort' + i} className="table-header" style={column.iconStyle} onClick={() => this.raiseSort(column.path)}>
                                        {column.label}
                                        {this.sortIcon(column)}
                                    </li>

                                if (column.path === "" && (!column.isCheckBox || column.isNbSp))
                                    return <li key={'key_ths_label' + i} >
                                        {column.label}
                                    </li>

                                if (column.isNbSp)
                                    return <li key={'key_ths_nbsp' + i}  >
                                        &nbsp;
                                </li>

                                if (column.isCheckBox)
                                    return (<li key={'key_ths_checkbox' + i} >
                                        {/* <div className={!column.isCheckBoxActive ? "customCheckbox customCheckboxWOlabel disabled" : this.props.isIntermediate ? "customCheckbox singleSlectAllDiv " : "customCheckbox customCheckboxWOlabel"}>
                                            <input id="selectAll" type="checkbox" onChange={(e) => this.checkBoxChange(e)} checked={this.props.isSelectAll} />
                                            <label htmlFor="selectAll">&nbsp;</label>
                                        </div> */}
                                        <div className={!column.isCheckBoxActive ? `customCheckbox customCheckboxWOlabel disabled ${this.props.isIndeterminate ? "singleSlectAllDiv" : "" }` :  `customCheckbox customCheckboxWOlabel ${this.props.isIndeterminate ? "singleSlectAllDiv" : "" } `}>
                                            <input id="selectAll" type="checkbox" onChange={(e) => this.checkBoxChange(e)} checked={this.props.isSelectAll} />
                                            <label htmlFor="selectAll">&nbsp;</label>
                                        </div>
                                    </li>)
                            })
                        }
                    </ul>
            </React.Fragment>
        );
    }
}

