import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import HeaderComponent from '../layouts/header/HeaderComponent';
import SidebarComponent from '../layouts/sideBar/SidebarComponent';
import { TableHeader } from '../../controls/tableHeader';
import { NoRecordsFound } from '../../controls/noRecordsFound';
import PendingApprovalModal from '../actionspermissions/PendingApprovalModal';
import ApprovalSubmitModal from '../actionspermissions/ApprovalSubmitModal';
import { DATE_FORMAT_VALUE, MY_REQUEST, PENDING_APPROVAL, MAIN_DASHBOARD } from '../../utils/appConstants';
import http from '../../service/httpService';
import authService from '../../service/authService';
import { BaseService, unitInventory } from '../../service/api';
import { ApprovalWorkflow } from '../../service/api';
import _ from 'lodash';
import moment from 'moment';
import $ from 'jquery';
import GoToPagination from '../../controls/goToPagination';
import BeatLoader from "react-spinners/BeatLoader";

let todaysDate = new Date();
class DashboardPendingApproval extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            myRequestList: [],
            globalActions: [],
            sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
            showPopup: true,
            statusValue: null,
            loaderIconVisible: false,
            actionParam: "Action",
            glbActionParam: "Action",
            requeststatusmodal: {
                open: false,
                requestObject: ""
            },
            approvalsubmitmodal: {
                open: false,
                requestObject: ''
            },
            users: [],
            userSelectAll: false,
            userChecked: [],
            userType: "",
            actionComment: '',
            requestTypeList: [],
            reqId: 0,
            reqName: '',
            customersCount: undefined,
            regionsCount: undefined,
            countrysCount: undefined,
            locationsCount: undefined,
            unitsCount: undefined,
            unitsOfflineCount: 0,
            unitsOnlineCount: 0,
            customStartDate: "",
            customEndDate: "",
            maxdate: new Date(),
            minDate: new Date(todaysDate.setMonth(todaysDate.getMonth() - 12)),
            approvalFixedHeader: '',
            currentPage: 1,
            pageSize: 25, 
            totalPages: null,
            loaderIconWidget: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }



    componentDidMount =  () => {
        document.body.style.background = "#f2f2f2";
        $("html").removeAttr("style");
        try {
            this.getDashboardCustomerRegionCountryCount();
            this.getDashboardLocationUnitCount();
            this.getRequestTypeList();
            Promise.resolve(this.getRequestList(1));
            window.addEventListener('resize', this.updateDimensions);

        } catch (error) {
            this.setState({
                loaderIconVisible: false,
            });
        }
    }


    updateDimensions() {
        const windowHeight = $(window).height();
        const $tableBody = $('.dashboardTableSrlBar');
        const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }

    componentDidUpdate() {
        this.updateDimensions()
    }

    getDashboardCustomerRegionCountryCount =  () => {
        this.setState({ loaderIconWidget: true });
        http({
            method: 'GET',
            url: `${BaseService.root}${ApprovalWorkflow.dashboardCustomerRegionCountryCount}`,
            data: {}
        }).then(response => {
            this.setState({ loaderIconWidget: false });
            if (response && response.data) {
                this.setState({
                    loaderIconWidget: false,
                    customersCount: response.data.data ? response.data.data.customerCount : 0,
                    regionsCount: response.data.data ? response.data.data.regionCount : 0,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconWidget: false });
        });
    }

    getDashboardLocationUnitCount =  () => {
        this.setState({ loaderIconWidget: true });
        http({
            method: 'GET',
            url: `${BaseService.root}${ApprovalWorkflow.dashboardLocationUnitCount}`,
            data: {}
        }).then(response => {
            this.setState({ loaderIconWidget: false });
            if (response && response.data) {
                const baseReponse = response.data.data
                this.setState({
                    loaderIconWidget: false,
                    locationsCount: baseReponse ? baseReponse.locationCount : 0,
                    unitsCount: baseReponse && baseReponse.units ? baseReponse.units.totalUnits : 0,
                    unitsOfflineCount: baseReponse && baseReponse.units ? baseReponse.units.Offline : 0,
                    unitsOnlineCount: baseReponse && baseReponse.units ? baseReponse.units.Online : 0,
                    countrysCount: response.data.data ? response.data.data.countryCount : 0
                });
            }
        }).catch(err => {
            this.setState({ loaderIconWidget: false });
        });
    }

    getRequestList =  (currentPage, sortColumn = this.state.sortColumn) => {
        this.setState({ loaderIconVisible: true });
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        const  {email: loggedinUser } = loginUser;
        let { customStartDate, customEndDate } = this.state;
        customStartDate = moment(new Date(customStartDate)).format("YYYY-MM-DD");
        customEndDate = moment(new Date(customEndDate)).format("YYYY-MM-DD");
        let requestBody = {
            "type": "showApprovals",
            "useremail": loggedinUser
        }
        
        if (this.state.reqId) {
            requestBody["statusFilter"] = this.state.reqId;
        }
        if (customStartDate.length > 0 && customEndDate.length > 0 && customStartDate !== "Invalid date" && customEndDate !== "Invalid date") {
            requestBody["startDate"] = customStartDate;
            requestBody["endDate"] = customEndDate;
        }
        let apiUrl = `${BaseService.root}${ApprovalWorkflow.userRequestList}`;
        let pageNumber = `?pageNum=` + currentPage;
        let pageLimit = '&pageSize=' + this.state.pageSize;
        apiUrl = apiUrl + pageNumber + pageLimit
        if(sortColumn && sortColumn.path){
			apiUrl = `${apiUrl}&sortOrder=${sortColumn.order}&sortBy=${sortColumn.path}`
		} else{
            apiUrl = `${apiUrl}&sortOrder=desc&sortBy=date_created`
        }
        http({
            method: 'post',
            url: apiUrl,
            data: requestBody
        }).then(response => {
            this.setState({ loaderIconVisible: false });
            if (response && response.data && response.data.data && response.data.data.response && response.data.data.response[0]) {
                const responseList = response.data.data.response.map(obj => { return { ...obj, actionName: 'Action', checked: false } });
                this.setState({
                    loaderIconVisible: false,
                    myRequestList: responseList,
                    globalActions: response,
                    totalPages: response.data.data.numberOfPages,
                    currentPage,
                    sortColumn
                });
        }else if(response && response.status === 200 && response.data.length === 0){
            this.setState({
                loaderIconVisible: false,
                myRequestList: [],
                totalPages: 0,
            });
        }else if(response && response.data && response.data.httpCode){
            this.setState({
                loaderIconVisible: false,
                myRequestList: [],
                totalPages: 0
            });
        }else{
            this.setState({
                loaderIconVisible: false,
                myRequestList: [],
                totalPages: 0
            });
        }
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                myRequestList: [],
                totalPages: 0
            });
        });

    }

    getRequestTypeList =  () => {
        this.setState({ loaderIconVisible: true });
        http({
            method: 'GET',
            url: `${BaseService.root}${ApprovalWorkflow.getRequestTypes}`,
            data: {}
        }).then(response => {
            this.setState({ loaderIconVisible: false });
            if (response && response.data ) {
            const reqTypeList = response.data;
            const sortedRequestType = _.sortBy(reqTypeList, ['display_name'])
            this.setState({
                loaderIconVisible: false,
                requestTypeList: sortedRequestType,
            });
        }else if(response && response.data && response.data.httpCode){
            this.setState({
                loaderIconVisible: false,
                requestTypeList: [],
            });
        }
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
            });
        });

    }

    onSelectRequestType = (e) => {
		this.setState({ loaderIconVisible: true });
		const { requestTypeList, reqId, reqName } = this.state;
		const custId = e.target.value;
		if(!custId) {
			this.setState({ loaderIconVisible: false, selectedCustomers: null, reqId: 0});
		}
		
		if (custId) {
            const customerInfo = requestTypeList.find((item) => item.id == +custId);
			if(_.isObject(customerInfo)) {
				this.setState({ 
					loaderIconVisible: false, 
					selectedCustomers: customerInfo, 
					reqId: customerInfo.id, 
					reqName: customerInfo.display_name,
				}, () => { this.getRequestList(1) });
				return;
			}
        }
        else {
            this.setState({ 
                loaderIconVisible: false, 
                reqId: 0, 
                reqName: "",
            }, () => { this.getRequestList(1) });
        }
		this.setState({ loaderIconVisible: false });
	}

    actionCommentHandler = (e) => {
        this.setState ({ actionComment: e.target.value })
    }


    onSubmitModal = async () => {
        this.setState({ loaderIconVisible: true });

        const { myRequestList, actionComment, glbActionParam } = this.state;
        const requesIdList = (_.map(_.filter(myRequestList, { checked: true }), 'requestId'));
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        const  {email: loggedinUser } = loginUser;
    
        const actionBtn =  {
            Approve: 'approveRequest',
            Reject: 'rejectRequest',
            ReferBack: 'referBackRequest'
        };

        const requestBody = {
            "type": actionBtn[glbActionParam] || 'referBackRequest',
            "approverEmail": loggedinUser,
            "requestIds": requesIdList,
            "approverComment": actionComment
        }

        await http({
            method: 'post',
            url: `${BaseService.root}${ApprovalWorkflow.userEventPost}`,
            data: requestBody
        }).then(response => {
            this.setState({ loaderIconVisible: false });
            let responseList = [...myRequestList];
            responseList = responseList.map(el => {
                return { ...el, checked: false }
            });
            // this.getRequestList();
            this.onSuccessCloseModal();
            this.setState({ loaderIconVisible: false, myRequestList: responseList, actionComment: "" }, () => {
				this.getRequestList(1)
				});
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
            });
        });
    }

    onOpenModal = (item) => {
        const {requestId} = item;
        let requestBody = {
            "request_id": requestId,
            "type": "showApprovals",
        }
        this.setState({ loaderIconVisible: true });
        http({
            method:'post',
            url:`${BaseService.root}${ApprovalWorkflow.getRequestDetails}`,
            data: requestBody,
                }).then(response=> {
        this.setState({
            loaderIconVisible: false,
            showPopup: true,
            statusValue: item,
            requeststatusmodal: {
                open: true,
                requestObject: response.data
            },
        });
    }).catch(err=> {
        this.setState({
        loaderIconVisible:false,
                    });
                });
    }

    onCloseModal = () => {
        
        this.setState({
            loaderIconVisible: false,
            showPopup: false,
            requeststatusmodal: {
                open: false
            },
            approvalsubmitmodal: {
                open: false
            },
            statusValue: null
        });
    }

    onSuccessCloseModal = async () => {
        /* code commented for remove checked record for when modal cancel button */
        /* const { myRequestList } = this.state;
        let responseList = [...myRequestList];
            responseList = responseList.map(el => {
                return { ...el, checked: false }
            }); */
        this.setState({
            requeststatusmodal: {
                open: false
            },
            actionComment: "",
            approvalsubmitmodal: {
                open: false
            }
        })
        /* code commented for loader disable for modal close button issue */
        // await Promise.resolve(this.getRequestList());
    }

    onGlobalActionChange = (actionValue) => {
        const approvalsubmitmodal = {
            open: actionValue ? true : false,
            requestObject: actionValue
        }
        this.setState({ glbActionParam: actionValue, approvalsubmitmodal });
    }

    handleChange = (e) => {
        let responseList = [...this.state.myRequestList];
        responseList.forEach(r => {
            r.checked = e.target.checked;
        });
        this.isSelectEditDeleteGroup(responseList);
    }


    handleSingleCheckboxChange = (e, item) => {
        if (this.state.myRequestList && this.state.myRequestList.length > 0) {
            const responseList = [...this.state.myRequestList];
            const index = responseList.indexOf(item);
            responseList[index] = { ...responseList[index] };
            responseList[index].checked = e.target.checked;
            this.isSelectEditDeleteGroup(responseList);
        }
    }

 isSelectEditDeleteGroup = (responseList) => {
        let selectedGroups = responseList.filter(item => item.checked === true);
        this.setState({
            userSelectAll: selectedGroups.length === responseList.length,
            myRequestList: responseList,
        });
    }


    onChangeType = (e) => {
        const { userType } = this.state;
        this.setState({ userType: userType })
    }

    onSort = sortColumn => {
        // this.setState({
        //     sortColumn: sortColumn
        // });
        this.getRequestList(1, sortColumn)
    }

    onHandleDateChange = (date,field) => {
        this.setState({[field]:date}, () => { this.getRequestList(1) });
       }

       resetDatepicker = (e) => {
        e.preventDefault();
        return false;
    }

    reviewUnitInventory = async (e) => {
        const reviewDetails = this.state.requeststatusmodal.requestObject;
        if(reviewDetails){
            const unitRequestId = reviewDetails && reviewDetails.requestId;
            this.setState({ loaderIconVisible: true });
            await http({
                method: 'get',
                url: `${BaseService.root}${unitInventory.reviewUnitTemplate}${unitRequestId}`,
                data: {}
            }).then(response => {
                if (response.data && response.data.body.fileUrl) {
                    window.location = response.data.body.fileUrl;
                    this.setState({
                        loaderIconVisible: false,
                        S3Url: response.data.body.fileUrl,
                    });
                }
                else {
                    this.setState({ loaderIconVisible: false }); 
                }
            }).catch(err => {
                this.setState({ loaderIconVisible: false });
            });
        }


    }

            /* 
      Pagination
    */
      onPageChange = (currentPage) => {
		this.setState({ loaderIconVisible: true },()=>{
			this.getRequestList(currentPage);
		});
	}

    render() {
        let { sortColumn, requeststatusmodal, approvalsubmitmodal, loaderIconVisible, myRequestList, actionParam, glbActionParam, actionComment, requestTypeList, customersCount, regionsCount, countrysCount, locationsCount, unitsCount, unitsOfflineCount, unitsOnlineCount, customStartDate, customEndDate, maxdate, minDate, approvalFixedHeader, totalPages, currentPage, pageSize } = this.state;
        let userAuthFeature = authService.getFeature("/dashboardPendingApproval");
        let orderId = 1;
        let responseData = myRequestList ? myRequestList : [];
        let checkboxSelected = responseData.filter(item => item.checked === true).length ? true : false;

        const filterResponseList = responseData.filter(el => (el.checked === true) && (el.requestType.type === "Activate Customer User" || el.requestType.type === "Deactivate Customer User" || el.requestType.type === "Add IOT Software" || el.requestType.type === "Add Controller Software"));
        let rowNumber = (currentPage * pageSize) - pageSize;

        let checkedReqType = filterResponseList.length > 0 ;
        
        responseData.forEach(item => {
			item.order_id = orderId++
		});
        responseData = _.orderBy(responseData, [sortColumn.path], [sortColumn.order]);
        
        let responseObj;
        let columns = [
            { path: "requestId", label: "#" },
            { path: "requestType.type", label: "Request Type" },
            { path: "dateCreated", label: "Date Created" },
            { path: "comment", label: "Request Comments" },
            { path: "createdByEmail", label: "Submitted By" },
            { path: "", label: "", isCheckBox: true, isCheckBoxActive: userAuthFeature.is_editable }
        ];
        
        if (Array.isArray(responseData) && responseData.length) {
            const responseList = responseData.filter(el => el.requestId);

            responseObj = responseList.map((item, i) => {
                return (
                    <React.Fragment key={item.requestId}>
                        <ul className="tBody">
                            <li>{rowNumber + i + 1}</li>
                            <li><span className="anchorTag" onClick={() => this.onOpenModal(item)}>{item.requestType ? item.requestType.type : ""}</span></li>
                            <li>{item.dateCreated ? moment(new Date(item.dateCreated)).format(DATE_FORMAT_VALUE) : ""}</li>
                            <li>{item.comment}</li>
                            <li>{item.createdByEmail}</li>
                            <li>
                                <div className="customCheckbox customCheckboxWOlabel">
                                    <input id={`checkbox_${i}`} type="checkbox" value={item.checked}
                                        checked={item.checked}
                                        onChange={(e) => this.handleSingleCheckboxChange(e,item)} />
                                    <label htmlFor={`checkbox_${i}`}></label>
                                </div>
                            </li>
                        </ul>
                    </React.Fragment>
                )
            }
            )

        }
        else {
            responseObj = (<div className="actionsPermissionsTableLoader"><NoRecordsFound loaderIconVisible={loaderIconVisible} length={responseData !== undefined ? responseData.length : 0} classname={'norecordsfoundbluetext'} />
                 <BeatLoader
                  size={12}
                  margin={2}
                  color={"#123abc"}
                  loading={this.state.loaderIconVisible}
                  speedMultiplier={1}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                /></div>);
        }
        return (
            <React.Fragment>
                <div id="wrapper">
                    <HeaderComponent headerName="Dashboard" />
                    <SidebarComponent activeClass="dashboardNav" />
                    <div id="page-content-wrapper">
                    <BeatLoader
                     size={12}
                     style={{position: "absolute", top: "65%", right: "39.125em"}}
                     margin={2}
                     color={"#123abc"}
                     loading={this.state.loaderIconVisible}
                     speedMultiplier={1}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                    />
                        {/* User Story 82700: Frontend and UI/UX - Analysis and Planning <LoadingPopup loading={loaderIconVisible} /> */}
                        <div className="adminDivWrapper">
                            <div className="wrapperHeaderA">
                                <ul>
                                    <li>
                                        <a href="#" title={MAIN_DASHBOARD} className="dashboardNav active">{MAIN_DASHBOARD}</a>
                                    </li>
                                    <li>
                                    </li>
                                </ul>
                            </div>

                            <div className="topWidgetList">
                                <ul>
                                    <li>
                                        <div className="innerWrapperTable">
                                            <div className="innerWrapperRw">
                                                <span className="iconSpan"><img src={require('../../images/icons/customer.png')} alt="Customers" title="Customers" /></span>
                                                <div className="widgetContent">
                                                    <h4>Customers</h4>
                                                    <h5><BeatLoader
                                                      size={6}
                                                      margin={1}
                                                      color={"#123abc"}
                                                      loading={customersCount === undefined}
                                                      speedMultiplier={1}
                                                      aria-label="Loading Spinner"
                                                      data-testid="loader"
                                                      />{customersCount}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="innerWrapperTable">
                                            <div className="innerWrapperRw">
                                                <span className="iconSpan"><img src={require('../../images/icons/regions.png')} alt="Regions" title="Regions" /></span>
                                                <div className="widgetContent">
                                                    <h4>Regions</h4>
                                                    <h5><BeatLoader
                                                      size={6}
                                                      margin={1}
                                                      color={"#123abc"}
                                                      loading={regionsCount === undefined}
                                                      speedMultiplier={1}
                                                      aria-label="Loading Spinner"
                                                      data-testid="loader"
                                                      />{regionsCount}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="innerWrapperTable">
                                            <div className="innerWrapperRw">
                                                <span className="iconSpan"><img src={require('../../images/icons/country.png')} alt="Countries" title="Countries" /></span>
                                                <div className="widgetContent">
                                                    <h4>Countries</h4>
                                                    <h5><BeatLoader
                                                      size={6}
                                                      margin={1}
                                                      color={"#123abc"}
                                                      loading={countrysCount === undefined}
                                                      speedMultiplier={1}
                                                      aria-label="Loading Spinner"
                                                      data-testid="loader"
                                                      />{countrysCount}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="innerWrapperTable">
                                            <div className="innerWrapperRw">
                                                <span className="iconSpan"><img src={require('../../images/store/c-store.svg')} alt="Locations" title="Locations" /></span>
                                                <div className="widgetContent">
                                                    <h4>Locations</h4>
                                                    <h5><BeatLoader
                                                      size={6}
                                                      margin={1}
                                                      color={"#123abc"}
                                                      loading={locationsCount === undefined}
                                                      speedMultiplier={1}
                                                      aria-label="Loading Spinner"
                                                      data-testid="loader"
                                                      />{locationsCount}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="innerWrapperTable">
                                            <div className="innerWrapperRw">
                                                <span className="iconSpan"><img src={require('../../images/icons/unitsR.png')} alt="Units" title="Units" /></span>
                                                <div className="widgetContent">
                                                    <h4>Units</h4>
                                                    <h5><BeatLoader
                                                      size={6}
                                                      margin={1}
                                                      color={"#123abc"}
                                                      loading={unitsCount === undefined}
                                                      speedMultiplier={1}
                                                      aria-label="Loading Spinner"
                                                      data-testid="loader"
                                                      /> {unitsCount}</h5>
                                                    {/* User Story 77228: Admin Portal : Main Dashboard units count showing large number <div className="deviceStatus"><span className="offlineCount">{unitsOfflineCount} - Offline</span> <span className="onlineCount">{unitsOnlineCount} - Online</span></div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="wrapperHeaderA wrapperHeaderAA">
                                <ul>
                                    <li>
                                        <Link to="/welbiltDashboard" title={MY_REQUEST}>{MY_REQUEST}</Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboardPendingApproval" title={PENDING_APPROVAL} className="active">{PENDING_APPROVAL}</Link>
                                    </li>
                                    <li>&nbsp;</li>
                                </ul>
                            </div>
                            <div className="actionsPermissionsHeader">
                                <ul>
                                    <li>
                                        <div className="dateRangeFilter">
                                            <div className="fromDateDiv">
                                                <span className="formLabelText">From</span>
                                                <DatePicker type="text" class="form-control dayDatepicker" placeholderText="MM/DD/YYYY" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.resetDatepicker(e)} selected={customStartDate} onChange={(e) => this.onHandleDateChange(e,"customStartDate")} minDate={minDate} maxDate={maxdate} />
                                            </div>
                                            <div className="toDateDiv">
                                                <span className="formLabelText">To</span>
                                                <DatePicker type="text" class="form-control dayDatepicker" placeholderText="MM/DD/YYYY" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.resetDatepicker(e)} selected={customEndDate} onChange={(e) => this.onHandleDateChange(e, "customEndDate")} minDate={minDate} maxDate={maxdate} />
                                            </div>
                                        </div>
                                        <select className="form-control headerSelect" onChange={(e) => this.onSelectRequestType(e)}>
                                            <option value="">Select Request Type</option>
                                            {requestTypeList && requestTypeList.map((item,i) => (
														<option key={i} value={item.id} >{item.display_name}</option>
													))}
                                        </select>
                                    </li>
                                    <li>
                                        <React.Fragment><button type="button" className={checkboxSelected ? "btn btn-dark" : "btn btn-dark disabled"} data-toggle="modal" title="Approve" onClick={(e) => this.onGlobalActionChange("Approve")}>Approve</button>
                                        <button type="button" className={checkboxSelected ? "btn btn-dark" : "btn btn-dark disabled"} data-toggle="modal" title="Reject" onClick={(e) => this.onGlobalActionChange("Reject")}>Reject</button>
                                        <button type="button" className={!checkedReqType && checkboxSelected ? "btn btn-dark" : "btn btn-dark disabled"} data-toggle="modal" title="Refer Back" onClick={(e) => this.onGlobalActionChange("Refer Back")}>Refer Back</button> </React.Fragment>
                                    </li>
                                </ul>
                            </div>
                            <div className="actionsPermissionsTable">
                                <TableHeader
                                    sortColumn={sortColumn}
                                    onSort={this.onSort}
                                    selectAllChange={this.handleChange}
                                    isIndeterminate={responseData.length !== 0 && responseData.filter((i)=>i.checked === true).length > 0 && responseData.length !== responseData.filter((i)=>i.checked === true).length}
                                    columns={columns}
                                    isSelectAll={this.state.userSelectAll} />
                            </div>
                            <div className="dashboardTableSrlBar" style={{ height: `${approvalFixedHeader}px` }}>
                            <div className="actionsPermissionsTable">
                            {responseObj}
                            </div>
                            </div>
                            <GoToPagination
                                totalPageNumber={totalPages}
                                pageNumber={currentPage}
                                navigateTo={(p) => this.onPageChange(p)} />
                        </div>
                        <PendingApprovalModal open={requeststatusmodal.open} requestObject={requeststatusmodal.requestObject} downloadUnitInventory={(e) => this.reviewUnitInventory(e)} onSuccessModel={() => this.onSuccessCloseModal()} />
                        <ApprovalSubmitModal open={approvalsubmitmodal.open} 
                        requestObject={approvalsubmitmodal.requestObject} 
                        onSuccessModel={() => this.onSuccessCloseModal()} 
                        submit={() => this.onSubmitModal()} 
                        commentValue = { actionComment } 
                        updateComment={(e) => this.actionCommentHandler(e)} />
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default DashboardPendingApproval