import * as api_china from './api_china.js';
import * as api_london from './api_london.js';

let cn = false;
const hostname = window && window.location && window.location.hostname;
if (hostname === 'www.admin.welbiltconnect.cn' || hostname === 'admin.welbiltconnect.cn') {
    cn = true;
}

let config_path = cn ? api_china : api_london;

export const { BaseService,
    ApprovalWorkflow,
    UserManagement,
    userRoleURI,
    UserMgmtAddUser,
    unitAPI,
    unitInventory,
    UserProfile,
    unitRegister,
    GroupMgmtApi,
    CustomerMgmtApi,
    BrandMgmtApi,
    CustomerOrgMgmtApi,
    SoftwareMgmtApi,
    ModelMgmtApi,
    managePasswordAPI,
    AdminReportsAPI } = config_path;