
import fConsts from './featureConstants'

export default  {
 features : [
        {
            title :"User Profile",
            feature_name : fConsts.UserProfile,
            navigateUrl:"/myInformation",
            featuteIcon : require('../images/mainmenu/users.svg'),
            productedRoutes : ["/myInformation","/myinformationedit","/assignedUnitGroups","/assignedUnitGroupsEdit","/managePassword"],
            isMainMenu: false,
            featureAvailable:true
    
        },
        {
            title :"Notifications",
            feature_name : fConsts.Notifications,
            navigateUrl:"/notificationManagementView",
            featuteIcon : require('../images/mainmenu/users.svg'),
            productedRoutes : ["/notificationManagementView","/notificationManagement"],
            isMainMenu: false,
            featureAvailable:true
        },
        {
            title :"API Management",
            feature_name : fConsts.ApiManagement,
            navigateUrl:"/customerDashboard",
            featuteIcon : require('../images/mainmenu/users.svg'),
            productedRoutes : ["/customerDashboard"],
            isMainMenu: false,
            featureAvailable:true
        },
        {
            title :"Certificate Management",
            feature_name : fConsts.CertificateManagement,
            navigateUrl:"/customerCertificateMgmt",
            featuteIcon : require('../images/mainmenu/users.svg'),
            productedRoutes : ["/customerCertificateMgmt","/customerDashboard"],
            isMainMenu: false,
            featureAvailable:true
        },
        {
            title :"Customer Dashboards",
            feature_name : fConsts.CustomerDashboards,
            navigateUrl:"/customerDashboard",
            featuteIcon : require('../images/mainmenu/users.svg'),
            productedRoutes : ["/customerDashboard","/customerUserMgmtList","/customerUserRoleMgmtList","/userRoles"],
            isMainMenu: false,
            featureAvailable:true
        },
        {
            title:"Customer Management",
            feature_name : fConsts.CustomerManagement,
            navigateUrl:"/customerMgmtList",
            featuteIcon : require('../images/mainmenu/users.svg'),
            productedRoutes : ["/customerMgmtList","/addCustomer","/editCustomer","/customerSubscription","/EditCustomerSubscription"],
            isMainMenu: true,
            featureAvailable: true,
            linkTitle: "Customer Management"    
        },
        {
            title:"Customer User Emulation",
            feature_name : fConsts.CustomerUserEmulation,
            navigateUrl:"",
            featuteIcon : require('../images/mainmenu/users.svg'),
            productedRoutes : [],
            isMainMenu: false,
            featureAvailable: true,
            linkTitle: "Customer User Emulation"    
        },
        {
            title :"User Management",
            feature_name : fConsts.UserManagement,
            navigateUrl:"/adminUserMgmtList",
            featuteIcon : require('../images/mainmenu/usersA.svg'),
            productedRoutes : ["/adminUserMgmtList","/adminAddUser","/adminAssignGroups","/adminEditUser"],
            isMainMenu: true,
            featureAvailable:true,
            linkTitle: "Admin User Management"
        },
        {
            title :"User Role Management",
            feature_name : fConsts.UserRoleManagement,
            navigateUrl:"/adminUserMgmtRoleList",
            featuteIcon : require('../images/mainmenu/usersA.svg'),
            productedRoutes : ["/adminUserMgmtRoleList","/adminRoleView","/userrole"],
            isMainMenu: false,
            featureAvailable:true,
            linkTitle: "Admin User Management"
        },
        {
            title:"Unit Inventory Management",
            feature_name : fConsts.InventoryManagement,
            navigateUrl:"/inventoryManagement",
            featuteIcon : require('../images/mainmenu/UnitRegistrationAssignment.svg'),
            productedRoutes : ["/inventoryManagement","/addInventory","/addBulkInventory","/addInventoryConfirmation"],
            isMainMenu: true,
            featureAvailable:true,
            linkTitle: "Unit Inventory Management"
        },
        {
            title: "Subscription",
            feature_name : fConsts.Subscription,
            navigateUrl:"/subscription",
            featuteIcon : require('../images/mainmenu/warranty.svg'),
            productedRoutes : ["/subscription"],
            isMainMenu: true,
            featureAvailable: false,
            linkTitle: "Subscription"
        },
        {
            title:"Software Management",
            feature_name : fConsts.SoftwareManagement,
            navigateUrl:"/agentSoftwareList",
            featuteIcon : require('../images/mainmenu/plugins.svg'),
            productedRoutes : ["/agentSoftwareList","/controllerSoftwareList","/agentSoftwareUpload","/controllerSoftwareUpload","/softwareCreation","/editAgentSoftwareUpload","/editSoftwareCreation","/editControllerSoftwareUpload"],
            isMainMenu: true,
            featureAvailable:true,
            linkTitle: "Software Management"
        },
        {
            title:"Admin Reports",
            feature_name : fConsts.Reports,
            navigateUrl:"/fryerReports",
            featuteIcon : require('../images/mainmenu/report.svg'),
            productedRoutes : ["/fryerReports","/reportAssignUpdateList"],
            isMainMenu: true,
            featureAvailable:true,
            linkTitle: "Admin Reports"
        },
        {
            title :"Customer Group Management",
            feature_name : fConsts.GroupManagement,
            navigateUrl:"/groupMgmtList",
            featuteIcon : require('../images/mainmenu/group.png'),
            productedRoutes : ["/groupMgmtList","/addGroup","/viewGroup"],
            isMainMenu: true,
            featureAvailable:true,
            linkTitle: "Customer Group Management"
        },
        {
            title: "Brand Management",
            feature_name: fConsts.NewBrandOnboarding,
            navigateUrl: "/brandMgmtList",
            featuteIcon: require('../images/mainmenu/brandOnboard.png'),
            productedRoutes: ["/brandMgmtList","/addBrand", "/editBrand"],
            isMainMenu: true,
            featureAvailable: true,
            linkTitle: "Brand Management"
        },
        {
            title: "Unit Model Management",
            feature_name: fConsts.NewEquipmentModelOnboarding,
            navigateUrl: "/modelMgmtList",
            featuteIcon: require('../images/mainmenu/modelOnboarding.svg'),
            productedRoutes: ["/modelMgmtList", "/addModel", "/editModel"],
            isMainMenu: true,
            featureAvailable: true,
            linkTitle: "Unit Model Management"
        },
        {
            title :"Main Dashboard",
            feature_name : fConsts.KPIDashboards,
            navigateUrl:"/welbiltDashboard",
            featuteIcon : require('../images/mainmenu/users.svg'),
            productedRoutes : ["/welbiltDashboard", "/dashboardPendingApproval"],
            isMainMenu: false,
            featureAvailable:true,
        },
        {
            title :"EaaS Management",
            feature_name : fConsts.EaaSManagement,
            navigateUrl:"/eaasManagement",
            featuteIcon : require('../images/mainmenu/warranty.svg'),
            productedRoutes : ["/eaasManagement",],
            isMainMenu: true,
            featureAvailable:true,
            linkTitle: "EaaS Management"
        }, 
        {
            title :"Admin CN",
            feature_category : "Main Menu Apps",
            feature_name : fConsts.AdminChina,
            navigateUrl:"/adminChina",
            featuteIcon : require('../images/mainmenu/warranty.svg'),
            productedRoutes : ["/adminChina"],
            isMainMenu: true,
            featureAvailable:true,
            linkTitle: "Admin CN"
        }
    ]
}