import React, { useEffect , useState } from "react"
import { BaseService, ApprovalWorkflow } from '../../service/api';
import http from '../../service/httpService';
import { Loader, Section } from "../commonControls";

const DashBoardWidget = ({ title, imagePath, count }) => (
  <div className="innerWrapperTable">
      <div className="innerWrapperRw">
          <span className="iconSpan">
              <img src={imagePath} alt={title} title={title} />
          </span>
          <div className="widgetContent">
              <h4>{title}</h4>
              <h5>
              <Loader  size="sm" loading={count===undefined} />
              {count}
                </h5>
          </div>
      </div>
  </div>
)


const DashBoardWidgets = () =>{

    const [custCountryCount , setcustCountryCount] = useState({});
    const [locUnitCount , setlocUnitCount] = useState({});

    useEffect(()=>{
        loadWidgetsData();
    },[]);

    const loadWidgetsData = async() => {
      const [regionCount, locCount] = await Promise.all([getDashboardCustomerRegionCountryCount(), getDashboardLocationUnitCount()])
      try{
        if(regionCount && regionCount.data){
        setcustCountryCount(regionCount.data.data);
        }
        if(locCount && locCount.data){
          setlocUnitCount({...locCount.data.data})
          console.log("locCount", locCount);
        }
      }catch(error){
        console.log(error.message);
      }

    }

    const getDashboardCustomerRegionCountryCount = () => {
        return http({
            method: 'GET',
            url: `${BaseService.root}${ApprovalWorkflow.dashboardCustomerRegionCountryCount}`,
            data: {}
        });

    }

    const getDashboardLocationUnitCount = () => {
        
        return http({
            method: 'GET',
            url: `${BaseService.root}${ApprovalWorkflow.dashboardLocationUnitCount}`,
            data: {}
       });

    }

    return(
        <Section className="topWidgetList">
        <ul>
          <li>
            <DashBoardWidget 
            title="Customers"
            imagePath={require("../../images/icons/customer.png")}
            count={custCountryCount.customerCount}/>
          </li>
          <li>
          <DashBoardWidget 
            title="Regions"
            imagePath={require("../../images/icons/regions.png")}
            count={custCountryCount.regionCount}/>
          </li>
          <li>

          <DashBoardWidget 
            title="Countries"
            imagePath={require("../../images/icons/country.png")}
            count={custCountryCount.countryCount}/>
          </li>
          <li>
          <DashBoardWidget 
            title="Locations"
            imagePath={require("../../images/store/c-store.svg")}
            count={locUnitCount.locationCount}/>
          </li>
          <li>
          <DashBoardWidget 
            title="Units"
            imagePath={require("../../images/icons/unitsR.png")}
            count={locUnitCount.units?.totalUnits} />

          </li>
        </ul>
      </Section>
    )

}

export default DashBoardWidgets;