import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import { DATE_FORMAT_VALUE, REVIEW_UNIT_INVENTORY, REVIEW_UNIT_MESSAGE } from '../../utils/appConstants';
import moment from 'moment';
import _ from 'lodash';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};
class PendingApprovalModal extends Component {

    nextPath(path) {
        this.props.history.push(path);
    }
    constructor(props) {
        super(props);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    state = {
        open: false
    };

    onCloseModal = (e) => {
        this.props.onSuccessModel();
    };

    downloadUnitInventory = () => {
        this.props.downloadUnitInventory();
    }

    render() {
        const { open, requestObject } = this.props;
        const { streetNumber, streetName, city, zip_code, country } = requestObject && requestObject.requestType && requestObject.requestType.requestDetails?requestObject.requestType.requestDetails:{};
        return (
            <div style={styles}>
                {requestObject && requestObject.requestType ?
                    <Modal open={open} showCloseIcon={false} onClose={(e) => this.onCloseModal(e)}>
                        <div className="modal fade show" data-keyboard="true" data-backdrop="static" id="SignInSuccessModal" style={{ display: "block" }}>
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{requestObject.requestType.type} </h5>
                                        <button type="button" className="close" data-dismiss="modal" onClick={this.onCloseModal}>&nbsp;</button>
                                    </div>
                                    {(requestObject.requestType.type === "Add User" || requestObject.requestType.type === "Edit User") && requestObject.requestType ?
                                        (<div className="modal-body" style={{'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto'}}>
                                            {requestObject.requestType.requestDetails ?
                                                <div className="pendingApprovalsTableExp">
                                                    <ul>
                                                        <li>User Name</li>
                                                        <li>{requestObject.requestType.requestDetails.username}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>User Role</li>
                                                        <li>{requestObject.requestType.requestDetails.roleName}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Email</li>
                                                        <li>{requestObject.requestType.requestDetails.email}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Phone Number</li>
                                                        <li>{requestObject.requestType.requestDetails.country_code} {requestObject.requestType.requestDetails.phoneNumber}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Country</li>
                                                        <li>{requestObject.requestType.requestDetails.country}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Assigned Customer Groups</li>
                                                        <li>{_.join(_.map(requestObject.requestType.requestDetails.groups, item => item.groupName), ', ')}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Comment</li>
                                                        <li>{requestObject.requestType.requestDetails.comment}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Created By</li>
                                                        <li>{requestObject.requestType.requestDetails.requesterEmail}<span>{requestObject.requestType.requestDetails.requesterRoleName}</span></li>
                                                    </ul>
                                                </div> : null}
                                            <div className="myRequestTableExp">
                                                <ul className="tHead">
                                                    <li>Level</li>
                                                    <li>User</li>
                                                    <li>Status</li>
                                                    <li>Comments</li>
                                                    <li>Last Action On</li>
                                                </ul>
                                                {requestObject.requestType.events.map((item, i) =>
                                                    <ul className="tBody">
                                                        <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                        <li>{item.email}</li>
                                                        <li>{item.status}</li>
                                                        <li>{item.comment}</li>
                                                        <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                    </ul>)}

                                            </div>

                                        </div>) : (requestObject.requestType.type === "Add Unit Inventory" || requestObject.requestType.type === "Edit Unit Inventory" || requestObject.requestType.type === "Delete Unit Inventory" || requestObject.requestType.type === "Deprovisioning Unit") && requestObject.requestType ?
                                            <div className="modal-body">
                                                {requestObject.requestType.requestDetails ?
                                                    <div className="inventoryModalTable">
                                                        <ul className="tHead">
                                                            <li>#</li>
                                                            <li>Serial Number</li>
                                                            <li>Brand</li>
                                                            <li>Model Name</li>
                                                            <li>Registration Code</li>
                                                        </ul>
                                                        <ul className="tBody">
                                                            <li>{requestObject.requestType.requestDetails.ID ? 1 : 1}</li>
                                                            <li>{requestObject.requestType.requestDetails.SERIALNO ? requestObject.requestType.requestDetails.SERIALNO : "NA"}</li>
                                                            <li>{requestObject.requestType.requestDetails.BRANDNAME ? requestObject.requestType.requestDetails.BRANDNAME : "NA"}</li>
                                                            <li>{requestObject.requestType.requestDetails.MODELNAME ? requestObject.requestType.requestDetails.MODELNAME : "NA"}</li>
                                                            <li>{requestObject.requestType.requestDetails.REGISTRATION_CODE ? requestObject.requestType.requestDetails.REGISTRATION_CODE : "NA"}</li>
                                                        </ul>
                                                    </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                {requestObject.requestType.requestDetails.length > 5 ? <div className="buttonAlignCenter">
                                                <p>{REVIEW_UNIT_MESSAGE}</p>
                                                    <button className="btn btn-dark" type="button">{REVIEW_UNIT_INVENTORY}</button>
                                                </div> : null}
                                                <div className="myRequestTableExp">
                                                    <ul className="tHead">
                                                        <li>Level</li>
                                                        <li>User</li>
                                                        <li>Status</li>
                                                        <li>Comments</li>
                                                        <li>Last Action On</li>
                                                    </ul>
                                                    {requestObject.requestType.events.map((item, i) =>
                                                        <ul className="tBody">
                                                            <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                            <li>{item.email}</li>
                                                            <li>{item.status}</li>
                                                            <li>{item.comment}</li>
                                                            <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                        </ul>)}

                                                </div></div> : (requestObject.requestType.type === "Add Bulk Unit Inventory" || requestObject.requestType.type === "Edit Unit Bulk Inventory" || requestObject.requestType.type === "Delete Unit Bulk Inventory" || requestObject.requestType.type === "Deprovisioning Unit") && requestObject.requestType ?
                                            <div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                {requestObject.requestType.requestDetails ?
                                                    <div className="inventoryModalTable">
                                                        <ul className="tHead">
                                                            <li>#</li>
                                                            <li>Serial Number</li>
                                                            <li>Brand</li>
                                                            <li>Model Name</li>
                                                            <li>Registration Code</li>
                                                        </ul>
                                                        {requestObject.requestType.requestDetails.map((item, i) =>
                                                                (
                                                                    <React.Fragment key={i}>
                                                                        <ul className="tBody">
                                                                            <li>{i + 1}</li>
                                                                            <li>{item.SERIALNO ? item.SERIALNO : "NA"}</li>
                                                                            <li>{item.BRANDNAME ? item.BRANDNAME : "NA"}</li>
                                                                            <li>{item.MODELNAME ? item.MODELNAME : "NA"}</li>
                                                                            <li>{item.REGISTRATION_CODE ? item.REGISTRATION_CODE : "NA"}</li>
                                                                        </ul>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                {requestObject.requestType.requestDetails.length >= 5 ? <div className="buttonAlignCenter">
                                                <p>{REVIEW_UNIT_MESSAGE}</p>
                                                    <button className="btn btn-dark" type="button" onClick={(requestObject) => this.props.downloadUnitInventory(requestObject)}>{REVIEW_UNIT_INVENTORY}</button>
                                                </div> : null}
                                                <div className="myRequestTableExp">
                                                    <ul className="tHead">
                                                        <li>Level</li>
                                                        <li>User</li>
                                                        <li>Status</li>
                                                        <li>Comments</li>
                                                        <li>Last Action On</li>
                                                    </ul>
                                                    {requestObject.requestType.events.map((item, i) =>
                                                        <ul className="tBody">
                                                            <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                            <li>{item.email}</li>
                                                            <li>{item.status}</li>
                                                            <li>{item.comment}</li>
                                                            <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                        </ul>)}

                                                </div></div> : (requestObject.requestType.type === "Add Brand" || requestObject.requestType.type === "Edit Brand" || requestObject.requestType.type === "Delete Brand") && requestObject.requestType ?
                                            <div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                {requestObject.requestType.requestDetails ?
                                                   <div class="pendingApprovalsTableExp">
                                                   <ul> 
                                                       <li>Brand Name</li> 
                                                       <li>{requestObject.requestType.requestDetails.brandName?requestObject.requestType.requestDetails.brandName:"NA"}</li>	 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>Brand Logo</li>  
                                                       <li><span class="brandImg">{requestObject.requestType.requestDetails.logoUrl ? <img src={requestObject.requestType.requestDetails.logoUrl} alt="" /> : ""}</span></li> 
                                                   </ul>  
                                                   <ul>  
                                                       <li>Comment</li>
                                                       <li>{requestObject.requestType.requestDetails.comment?requestObject.requestType.requestDetails.comment:"NA"}</li> 
                                                   </ul>  
                                               </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                <div className="myRequestTableExp">
                                                    <ul className="tHead">
                                                        <li>Level</li>
                                                        <li>User</li>
                                                        <li>Status</li>
                                                        <li>Comments</li>
                                                        <li>Last Action On</li>
                                                    </ul>
                                                    {requestObject.requestType.events.map((item, i) =>
                                                        <ul className="tBody">
                                                            <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                            <li>{item.email}</li>
                                                            <li>{item.status}</li>
                                                            <li>{item.comment}</li>
                                                            <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                        </ul>)}
                                                </div></div> : (requestObject.requestType.type === "Add Model" || requestObject.requestType.type === "Edit Model" || requestObject.requestType.type === "Delete Model") && requestObject.requestType ?
                                            <div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                {requestObject.requestType.requestDetails ?
                                                   <div class="pendingApprovalsTableExp">
                                                   <ul> 
                                                       <li>Brand Name</li> 
                                                       <li>{requestObject.requestType.requestDetails.brandName?requestObject.requestType.requestDetails.brandName:"NA"}</li>	 
                                                   </ul>
                                                   <ul> 
                                                       <li>Brand Type</li> 
                                                       <li>{requestObject.requestType.requestDetails.brandType?requestObject.requestType.requestDetails.brandType:"NA"}</li>	 
                                                   </ul>
                                                   <ul>
                                                        <li>Model Family</li>
                                                        <li>{requestObject.requestType.requestDetails.modelFamilyName ? requestObject.requestType.requestDetails.modelFamilyName : "NA"}</li>
                                                    </ul>
                                                   <ul> 
                                                       <li>Model Name</li> 
                                                       <li>{requestObject.requestType.requestDetails.modelName?requestObject.requestType.requestDetails.modelName:"NA"}</li>	 
                                                   </ul>
                                                   <ul>  						
                                                       <li>Unit Logo</li>  
                                                       <li><span class="modelImg">{requestObject.requestType.requestDetails.logoUrl ? <img src={requestObject.requestType.requestDetails.logoUrl} alt="" /> : ""}</span></li> 
                                                   </ul>  
                                                   <ul>  
                                                       <li>Comment</li>
                                                       <li>{requestObject.requestType.requestDetails.comment?requestObject.requestType.requestDetails.comment:"NA"}</li> 
                                                   </ul>  
                                               </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                <div className="myRequestTableExp">
                                                    <ul className="tHead">
                                                        <li>Level</li>
                                                        <li>User</li>
                                                        <li>Status</li>
                                                        <li>Comments</li>
                                                        <li>Last Action On</li>
                                                    </ul>
                                                    {requestObject.requestType.events.map((item, i) =>
                                                        <ul className="tBody">
                                                            <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                            <li>{item.email}</li>
                                                            <li>{item.status}</li>
                                                            <li>{item.comment}</li>
                                                            <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                        </ul>)}
                                                </div></div> : (requestObject.requestType.type === "Activate Customer User" || requestObject.requestType.type === "Deactivate Customer User") && requestObject.requestType ?
                                            <div className="modal-body">
                                                {requestObject.requestType.requestDetails ?
                                                   <div class="pendingApprovalsTableExp">
                                                   <ul> 
                                                       <li>Customer Name</li> 
                                                       <li className="disabled">{requestObject.requestType.requestDetails.customerName?requestObject.requestType.requestDetails.customerName:"NA"}</li>	 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>User Name</li>  
                                                       <li className="disabled">{requestObject.requestType.requestDetails.name ? requestObject.requestType.requestDetails.name : "NA"}</li> 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>User Role</li>  
                                                       <li className="disabled">{requestObject.requestType.requestDetails.roleName ? requestObject.requestType.requestDetails.roleName : "NA"}</li> 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>Email</li>  
                                                       <li className="disabled">{requestObject.requestType.requestDetails.username ? requestObject.requestType.requestDetails.username : "NA"}</li> 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>Phone Number</li>  
                                                       <li className="disabled">{requestObject.requestType.requestDetails.country_code} {requestObject.requestType.requestDetails.phone_number}</li> 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>Activation Status</li>  
                                                       <li>{requestObject.requestType.requestDetails.is_active === 0 ? "deactivated" : "activated"}</li> 
                                                   </ul> 
                                                   {/* <ul>  						
                                                       <li>Created By</li>  
                                                <li className="disabled">{requestObject.requestType.requestDetails.requesterEmail}<span>{requestObject.requestType.requestDetails.requesterRoleName}</span></li> 
                                                   </ul>    */}
                                               </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                <div className="myRequestTableExp">
                                                    <ul className="tHead">
                                                        <li>Level</li>
                                                        <li>User</li>
                                                        <li>Status</li>
                                                        <li>Comments</li>
                                                        <li>Last Action On</li>
                                                    </ul>
                                                    {requestObject.requestType.events.map((item, i) =>
                                                        <ul className="tBody">
                                                            <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                            <li>{item.email}</li>
                                                            <li>{item.status}</li>
                                                            <li>{item.comment}</li>
                                                            <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                        </ul>)}
                                                </div></div> : (requestObject.requestType.type === "Add IOT Software" || requestObject.requestType.type === "Add Controller Software" || requestObject.requestType.type === "Edit Controller Software" || requestObject.requestType.type === "Edit IOT Software" || requestObject.requestType.type === "Delete IOT Software") && requestObject.requestType ?
                                            <div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                {requestObject.requestType.requestDetails ?
                                                   <div class="pendingApprovalsTableExp">
                                                   <ul> 
                                                       <li>Brand Name</li> 
                                                       <li>{requestObject.requestType.requestDetails.brandName?requestObject.requestType.requestDetails.brandName:"NA"}</li>	 
                                                   </ul> 
                                                   <ul>
                                                        <li>Model Family</li>
                                                        <li>{requestObject.requestType.requestDetails.modelFamilyNames ? _.join(_.map(requestObject.requestType.requestDetails.modelFamilyNames, item => item), ', ') : "NA"}</li>
                                                    </ul>
                                                   <ul>  						
                                                       <li>Model Name</li>  
                                                       <li>{requestObject.requestType.requestDetails.modelNames ? _.join(_.map(requestObject.requestType.requestDetails.modelNames, item => item), ', ') : "NA"}</li> 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>Software Update</li>  
                                                       <li>{requestObject.requestType.requestDetails.fileName ? requestObject.requestType.requestDetails.fileName : "NA"}</li> 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>Software Version</li>  
                                                       <li>{requestObject.requestType.requestDetails.version ? requestObject.requestType.requestDetails.version : "NA"}</li> 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>Release Notes</li>  
                                                       <li>{requestObject.requestType.requestDetails.description ? requestObject.requestType.requestDetails.description : "NA"}</li> 
                                                   </ul> 
                                                   <ul>  						
                                                       <li>Customer Group Name</li>  
                                                <li>{requestObject.requestType.requestDetails.customersGroupDetails.length} ({requestObject.requestType.requestDetails.customersGroupDetails ? _.join(_.map(requestObject.requestType.requestDetails.customersGroupDetails, item => item.short_name ? item.name + "(" + item.short_name + ")": item.name ), ', ') : "NA"})</li> 
                                                   </ul>  
                                                   <ul>  
                                                       <li>Comment</li>
                                                       <li>{requestObject.requestType.requestDetails.comment?requestObject.requestType.requestDetails.comment:"NA"}</li> 
                                                   </ul>  
                                               </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                <div className="myRequestTableExp">
                                                    <ul className="tHead">
                                                        <li>Level</li>
                                                        <li>User</li>
                                                        <li>Status</li>
                                                        <li>Comments</li>
                                                        <li>Last Action On</li>
                                                    </ul>
                                                    {requestObject.requestType.events.map((item, i) =>
                                                        <ul className="tBody">
                                                            <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                            <li>{item.email}</li>
                                                            <li>{item.status}</li>
                                                            <li>{item.comment}</li>
                                                            <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                        </ul>)}
                                                </div></div> : <div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                            {requestObject.requestType.requestDetails ?
                                                <div className="pendingApprovalsTableExp">
                                                    <ul>
                                                        <li>Customer Admin Name</li>
                                                        <li>{requestObject.requestType.requestDetails.admin_user_name}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Customer Name</li>
                                                        <li>{requestObject.requestType.requestDetails.customerName}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Location</li>
                                                        <li>{`${streetNumber}, ${streetName},`} {city ? city+',': ''}  {zip_code ? zip_code+',': ''} {country}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Start Date</li>
                                                        <li>{moment(new Date(requestObject.requestType.requestDetails.subsCriptionStartDate)).format(DATE_FORMAT_VALUE)}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>End Date</li>
                                                        <li>{moment(new Date(requestObject.requestType.requestDetails.subsCriptionEndDate)).format(DATE_FORMAT_VALUE)}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>KC3.0 Offering</li>
                                                        <li>{requestObject.requestType.requestDetails.subscribedFeatures && _.join(_.map(requestObject.requestType.requestDetails.subscribedFeatures[0].features, item => item.feature_name, ', '))}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>C2C API Offering</li>
                                                        <li>{requestObject.requestType.requestDetails.subscribedFeatures && requestObject.requestType.requestDetails.subscribedFeatures[1] && _.join(_.map(requestObject.requestType.requestDetails.subscribedFeatures[1].features, item => item.feature_name, ', '))}</li>
                                                    </ul>
                                                </div> : null}
                                            <div className="myRequestTableExp">
                                                <ul className="tHead">
                                                    <li>Level</li>
                                                    <li>User</li>
                                                    <li>Status</li>
                                                    <li>Comments</li>
                                                    <li>Last Action On</li>
                                                </ul>
                                                {requestObject.requestType.events.map((item, i) =>
                                                    <ul className="tBody">
                                                        <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                        <li>{item.email}</li>
                                                        <li>{item.status}</li>
                                                        <li>{item.comment}</li>
                                                        <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                    </ul>)}

                                            </div></div>}
                                </div>
                            </div>
                        </div>
                    </Modal> : null}
            </div>
        );
    }
}

export default PendingApprovalModal