export default {
    Analytics : "Analytics",
    ApiManagement : "API Management",
    CertificateManagement: "Certificate Management",
    CustomerDashboards : "Customer Dashboards",
    CustomerManagement : "Customer Management",
    CustomerUserEmulation : "Customer User Emulation",
    InventoryManagement : "Inventory Management",
    KPIDashboards : "KPI Dashboards",
    NewBrandOnboarding : "New Brand Onboarding",
    NewEquipmentModelOnboarding : "New Equipment Model # Onboarding",
    Notifications : "Notifications",
    UserProfile : "User Profile",
    UserManagement: "User Management",
    UserRoleManagement : "User Role Management",
    SiteManagement : "Organization Management",
    Subscription : "Subscriptions",
    TransactionLogging : "Transaction Logging",
    WarrantyManagement : "Warranty Management",
    Reports: "Reports",
    SoftwareManagement: "Software Management",
    GroupManagement: "Group Management",
    Service: "Service",
    EaaSManagement : "EaaS Management",
    AdminChina : "KC CN Admin"
}

export  const welbiltAdminFeatures = [
    {featureId: 18, isViewOnly: true, isEditable: false, featureName: "Analytics"},
    {featureId: 7, isViewOnly: true, isEditable: false, featureName: "Certificate Management"},
    {featureId: 13, isViewOnly: true, isEditable: false, featureName: "Notifications"},
    {featureId: 14, isViewOnly: true, isEditable: false, featureName: "Subscriptions"},
    {featureId: 10, isViewOnly: true, isEditable: false, featureName: "Transaction Logging"},
    {featureId: 16, isViewOnly: true, isEditable: false, featureName: "Warranty Management"},    
    {featureId: 3, isViewOnly: true, isEditable: false, featureName: "User Role Management"},
    {featureId: 19, isViewOnly: true, isEditable: false, featureName: "Group Management"},
    ];