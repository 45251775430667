import React from 'react';
import { Link } from "react-router-dom";
import { KCList, KCListItem, Section } from '../../../controls/commonControls';

const SidebarComponent = ({ activeClass }) => (
    <Section id="sidebar-wrapper">
        <KCList className="sidebarNav">
            <KCListItem className="brandName"/>
            <KCListItem>
                <Link to="/welbiltDashboard" title="Dashboard" className={`dashboardNav ${activeClass === 'dashboardNav' ? 'active' : ''}`}>Dashboard</Link>
            </KCListItem>
        </KCList>
        <KCList className="sidebarNav bottomNav">
            <KCListItem>
                <Link to="/mainMenu" className={`menuNav ${activeClass === 'menuNav' ? 'active' : ''}`} title="Main Menu" >Main Menu</Link>
            </KCListItem>
        </KCList>
    </Section>
)

export default SidebarComponent