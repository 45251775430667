import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class ErrorModal extends Component {

    state = {
        open: false
    };

    onCloseModal = () => {
        this.props.onCloseModel();
        //this.props.history.push(`/`);
    };

    render() {
        const { open, message } = this.props;
        const inlineStyle = { display: "none" };
        if (open) {
            inlineStyle.display = "block";
        }
        return (
            <div style={styles}>
                <Modal className='SignInSuccessModal'  size="md" show={open} centered animation={false} backdrop="static">
                    <Modal.Body className='loginModal'>
                        <div className="errorModalContent">{message}</div>
                    </Modal.Body>
                    <Modal.Footer className='textAlignCenter'>
                        <button className="btn btn-secondary errorModalContentOKBtn" data-dismiss="modal" type="button" onClick={() => this.onCloseModal()}>OK</button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default ErrorModal;