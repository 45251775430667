import React, {useEffect, useState} from 'react';
import 'react-responsive-modal/styles.css';
import Modal from 'react-bootstrap/Modal';
import { Section } from '../commonControls';


const ApprovalModal = ({ open, onClose, onSubmit, approvalType }) => {

    const [comment, setComment] = useState("");
    const [submitting, setSubmitting] = useState(false);

    useEffect(()=>{
        setComment("")
        setSubmitting(false);
    },[open]);

    const onApprovalSubmit = (comment) =>{
        setSubmitting(true)
        onSubmit(comment)
    }


    return (
        <Section style={{ fontFamily: "sans-serif", textAlign: "center" }}>
            <Modal className='confirmationYesNoModal' 
            size="md" show={open} onHide={onClose} centered animation={false} backdrop="static">
                <Modal.Body>
                    <div className="confirmationYesNo">
                        <h5>Request</h5>
                        <textarea name="approveComment" 
                        className="form-control" 
                        placeholder="Please fill more information about your request." 
                        onChange={(e)=>setComment(e.target.value)}
                        ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer className='textAlignCenter'>
                    <button className="btn btn-secondary noBtn"
                        type="button" onClick={onClose}>Cancel</button>
                    <button
                        className={comment ? 'btn btn-secondary yesBtn' : 'btn btn-secondary yesBtn disabled'}
                        disabled={submitting}
                        type="button" onClick={() => onApprovalSubmit(comment)}>{approvalType.value}</button>
                </Modal.Footer>
            </Modal>
        </Section>
    );
}

export default ApprovalModal