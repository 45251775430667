import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import HeaderComponent from '../layouts/header/HeaderComponent';
import SidebarComponent from '../layouts/sideBar/SidebarComponent';
import { Section } from '../../controls/commonControls';

import auth from '../../service/authService';



const MainMenu = () => {

	useEffect(() => {
		document.body.style.backgroundColor = "#333333";
		$("div").removeClass("modal-backdrop fade show");
		if (!auth.getJwt())
			return this.props.history.push('/');

	}, [])

	const features = auth.getAllFeatures() || [];
	const menuFeatures = features.filter(f => f.isMainMenu && f.featureAvailable);

	return (
		<Section id="wrapper">
			<HeaderComponent headerName="Main Menu" activeClass="menuNav" />
			<SidebarComponent activeClass="menuNav" />
			<Section id="page-content-wrapper">
				<Section className="mainMenuGrid">
					<MenuItems items={menuFeatures} />
				</Section>
			</Section>
		</Section>
	)
};





const MenuItems = ({ items }) => (
	<ul>{items.map((item, i) => <MenuItem key={i} property={item} />)}</ul>
)

const MenuItem = ({ property }) => {
	return (
		<li>
			<span>
				<Link to={property.navigateUrl}>
					<img src={property.featuteIcon} alt={property.linkTitle} title={property.linkTitle} />
					<h5>{property.linkTitle}</h5>
				</Link>
			</span>
		</li>
	)
}

export default MainMenu;