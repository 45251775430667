import React, { useState, useEffect }  from 'react';
import HeaderComponent from '../layouts/header/HeaderComponent';
import SidebarComponent from '../layouts/sideBar/SidebarComponent';
import $ from 'jquery';

import { DashboardHeader,
     DashboardNavigationTab,
      DashBoardWidgets,
      RequestList,
      ApprovalList
    } from '../../controls/dashboard';
import { Section } from '../../controls/commonControls';

const MainDashboard = () => {

    useEffect(() => {
        document.body.style.background = "#f2f2f2";
        $("html").removeAttr("style");
    }, [])
  
  const [activeTab, setActiveTab] = useState("MyRequest")

   return (
    <>
      <Section id="wrapper">
        <HeaderComponent headerName="Dashboard" />
        <SidebarComponent activeClass="dashboardNav" />
        <Section id="page-content-wrapper">
          <Section className="adminDivWrapper">
            <DashboardHeader />
            <DashBoardWidgets />
            <DashboardNavigationTab onTabChange={(activeTab)=>setActiveTab(activeTab)}/>
            {activeTab === "MyRequest" && <RequestList />}
            {activeTab === "MyApproval" && <ApprovalList />}
          </Section>
        </Section>
      </Section>
    </>
  );
}


export default MainDashboard;