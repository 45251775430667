import { combineReducers } from 'redux';
import userReducer from './stateData/userReducer';
import customerReducer from './stateData/customerReducer';
import softwareReducer from './stateData/softwareReducer';

const appReducer = combineReducers({
  userReducer:userReducer,  
  customerReducer:customerReducer,
  softwareReducer:softwareReducer,
});

/**
 * Root reducer used to handle applications states data before/after login and logout applications. 
 * In order to reset all reducers back to their initial states when user logout,
 * rewrite rootReducer to assign '{}' to state when logout
 */
const rootReducer = (state, action) =>{
    if(action.type === 'LOG_OUT'){
      state = {};
    }
    return appReducer(state, action);
}

export default rootReducer;