import React from 'react';

const RequestStatus = ({ status }) => {
    const validStatus = {
        "Approved": "Approved",
        "Rejected": "Rejected",
        "Re-Submit": "Refer Back",
        "Approver 1": "Pending with Approver 1",
        "Approver 2": "Pending with Approver 2",
    }
    return (validStatus[status])
}

export default RequestStatus;