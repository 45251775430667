import React from 'react';

export const NoRecordsFound = ({ loaderIconVisible, length, classname, text }) => (
    <>
        {loaderIconVisible === false && length === 0 && <div className={classname}>
            {text ? text : "No records found."}
        </div>}
    </>
)

export default NoRecordsFound