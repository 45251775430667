import React, { Component } from 'react';
import MainLayout from './components/layouts/MainLayout';
import Routes from './routers/routes';


const App = () => {
  return (
    <>
      <MainLayout />
      <Routes />
    </>
  );
  
}

export default App;
