import React, { useState, useEffect } from 'react';
import { BaseService, ApprovalWorkflow } from '../../service/api';
import http from '../../service/httpService';
import _ from 'lodash';

const RequestTypeSelector = ({ onChange }) => {

    const [requestType, setRequestType] = useState([])

    useEffect(() => {
        getRequestTypeList();
    }, [])


    const getRequestTypeList = async () => {
        try {
            const response = await http({
                method: 'GET',
                url: `${BaseService.root}${ApprovalWorkflow.getRequestTypes}`,
                data: {}
            });

            if (response && response.data) {
                const reqTypeList = _.sortBy(response.data, ['display_name']);
                setRequestType(reqTypeList)
            }
        } catch (error) {
            console.log("Failed to load request type list");
        }
    }

    const handleOnSelect = (e) => {
        const data = requestType.find(item => item.id == e.target.value);
        onChange(data);
    }

    return (
        <select name="request_type_selector"
            title='Select Request Type'
            className="form-control headerSelect"
            onChange={handleOnSelect}
            style={{ width: "250px" }}
        >
            <option value="">Select Request Type</option>
            {requestType.map(item => (<option key={item.id} value={item.id}>{item.display_name}</option>))}
        </select>
    )
}

export default RequestTypeSelector;