import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import LogoutForm from "../../logout/LogoutForm";
import MainLayout from '../../../components/layouts/MainLayout';
import pointerDevice from '../../../utils/pointerDeviceFinder';
import ErrorModal from '../../modal/error-model';
import auth from '../../../service/authService';
import broadCastTokenEvent from '../../../events/tokenAuthrizer';
import authHelper from '../../../utils/authHelper';
import messageConfig from '../../../utils/messageConfig';

class HeaderComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.nextPath = this.nextPath.bind(this);
        this.state = {
            isHidden: false,
        }
    }

    componentDidMount = () => {
        this.subscription = broadCastTokenEvent.IsReceiveJwtTokenExpired().subscribe(isJwtExpired => {
            if (isJwtExpired)
                this.setState({ IsTokenExpired: isJwtExpired });
        });
    }

    nextPath(path) {
        this.props.history.push("/");
    }

    showLoginForm = (event, isHidden) => {
        this.setState({
            isHidden: !isHidden
        });
        event.preventDefault();
    }

    hideLoginForm = (event) => {
        event.preventDefault();
        this.setState({
            isHidden: false
        });
        if (pointerDevice.IsTouchDevice() && event.target) {
            let targetClass = event.target.getAttribute('class');
            if (targetClass && targetClass.indexOf('navFilter') !== -1) {
                this.showFilter(event, false);
            }
        }
    }

    errorCloseModel = () => {
        authHelper.deleteLocalSession();
        window.location.href = '/';
    }

    render() {
        let { activeClass, headerName } = this.props;
        let { isHidden } = this.state;
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        const  {email: loggedinUser } = loginUser;
        let IsTokenExpired = !this.state.IsTokenExpired ? auth.IsTokenTimeExipired() : this.state.IsTokenExpired;
        let logoutForm = null
        if (isHidden) {
            logoutForm = (<LogoutForm hideFlag={isHidden} hideProfileEditBtn={false} editinfo={this.editInformation} nextPath={this.nextPath} hideLoginForm={(e) => this.hideLoginForm(e)} />)
        }

        return (
            <React.Fragment>
                <ErrorModal open={IsTokenExpired ? true : false} message={messageConfig.SessionExpire} onCloseModel={() => this.errorCloseModel()} />
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-Welbilt">
                    <div className="navbar-header">
                        <Link className="navbar-brand welbiltRLogo" title="Welbilt - Bringing innovation to the table" to={(headerName === "Main Menu") ? "/welbiltDashboard" : (this.props.headerName === "Under Construction" || this.props.headerName === "Un-Authorized" ) ? { display: 'none' } : { display: 'none' }}>&nbsp;</Link>
                        <Link className="navbar-brand sideNavBar" to="#">&nbsp;</Link>
                    </div>
                    <ul className="navbar-nav mr-auto titlebarLeft">
                        <li className="nav-item">
                            <Link className="nav-link" to='#' title={headerName}>{headerName}</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav mr-auto navbarRight">
                        <li className={isHidden ? "nav-item activeNavUser" : "nav-item"}>
                            <Link className={isHidden ? "nav-link navUser active" : "nav-link navUser"} title={loggedinUser} to="#" onClick={(e) => { this.showLoginForm(e, isHidden) }}><span id="spannavNotifiStatus"></span>&nbsp;</Link>
                        </li>
                    </ul>
                </nav>
                <MainLayout flagstate={isHidden} />
                <div ref={node => { this.pop = node }}>
                    {logoutForm}
                </div>
            </React.Fragment>
        )
    }
}
export default HeaderComponent