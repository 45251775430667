import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logOutApplication } from '../../actions/logout/logoutAction';
import fConsts from '../../utils/featureConstants';
import  backDropEvent from '../../events/backdropEvent';
import http from '../../service/httpService';
import authService from '../../service/authService';
import { UserProfile } from '../../service/api';
import auth from '../../utils/authHelper';
import $ from 'jquery';


const userImages = [
	{ name: "System Manager", img: require("../../images/users/owner.svg") },
	{ name: "Crem System Admin", img: require("../../images/users/owner.svg") },	
	{ name: "Service Technician", img: require("../../images/users/service.svg") },	
	{ name: "1st Line User", img: require("../../images/users/leaser.svg") },
	{ name: "2nd Line User", img: require("../../images/users/chef.svg") },
	{ name: "Crem User", img: require("../../images/users/manager.svg") }
]

class LogoutForm extends Component {
    constructor(props) {
        super(props);
        this.nextPath = this.nextPath.bind(this);
        this.hideUserDetails = this.hideUserDetails.bind(this);
        this.state = {
            showSignOutOnly: false,
            userData: {},
            emailfromTokan: '',
            countryCodeWithoutPlus: '',
        };
    }

    nextPath(path) {
        if (this.props.nextPath != undefined) {
            this.props.nextPath();
        }
        if (this.props.nextPathNav != undefined) {
            this.props.nextPathNav();
        }
    }

    editinfo = () => {
        this.props.editinfo();
    }

    signoutForm = (e) => {
        auth.deleteLocalSession();
        this.ClearAll();
        this.callLogoutService();
    }

	ClearAll = ()=>{	

		this.setState({
		});

		var d = document.getElementById("spannavNotifiStatus");
		d.className = "";
	}	

    componentDidMount() {
        // this.getUserProfileInformation();
        if(this.props.hideFlag){
        let elem = backDropEvent.showBackDrop();
        let navHeader = document.querySelector('.navbar');
        navHeader.addEventListener('click', this.backdropClose);
        navHeader.addEventListener('touchstart', this.backdropClose);
        elem.addEventListener('click', this.backdropClose);
        elem.addEventListener('touchstart', this.backdropClose);
        }
    }

    backdropClose = (e) => {  
        if(e.target){  
        if(!$(e.target).hasClass('loadingProgress') && !$(e.target).hasClass('navUser'))
        {
           if($('body').has('#backdropElement')){ 
            document.querySelector('.navbar').removeEventListener('click', this.backdropClose);
            document.querySelector('.navbar').removeEventListener('touchstart', this.backdropClose);
            this.props.hideLoginForm(e);
            backDropEvent.hideBackdrop(); 
           }   
        }
       }
     }

    componentWillReceiveProps() {
        // this.getUserProfileInformation();
    }

    hideUserDetails(event) {
        //this.props.toggleHiddenArgument(event);
        this.setState({ showSignOutOnly: true })
    }

    
    
    callLogoutService= ()=>{

    /*    http({ 
            method: "PUT",
            url: BaseService.logout,
			data: {},
		}).then(function (response) {
			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: response.data
				},
			});
            this.props.logOutApplication();
		}).catch(err => {
			console.log('catch exception occured');
			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: "User successfully updated."
				},
			});
            this.props.logOutApplication();
        }); */
        
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: true,
                message: "User successfully updated."
            },
        });
        this.props.logOutApplication();
    }

    getUserProfileInformation = () => {
        let getUserData = userProfileMockData.user;
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        if (!loginUser)
            return this.props.history.push('/');

        const  {email: loggedinUser } = loginUser;
        // let token = localStorage.getItem("Access-Token");
        // var decrypt = jwt_decode(token)
        // let customerid = decrypt["custom:customerid"];
        // let customeremail = decrypt.email;
        this.setState({ emailfromTokan: loggedinUser });

        http({
            method: "GET",
            url: UserProfile.getUserProfileDetails,
            headers: {
                'customer_id': 1,
                'email': loggedinUser,
                'Content-Type': 'application/json'
            }, data: {}
        }).then(response => {
            this.setState({
                userData: response.data
            })
            this.setState({
                userstate: response.data.state,
                countryCodeWithoutPlus: response.data.country_code.replace("+", ""),
            });
            this.setState({ catchSelCountryCode: response.data.country_code })
        }).catch(err => {
            console.log('catch exception occured');
            this.setState({ userData: getUserData });
        });
    }

    render() {
        let { userData } = this.state;
        const showsignOut = this.state.showSignOutOnly;
        const hidden = this.props.hideFlag;
        let loginUser =   JSON.parse(localStorage.getItem("loginInfo"));
        const  {email: loggedinUser, role_name:loginRoleName } = loginUser;
        // let loggedinUser = loginUser && loginUser[0] && loginUser[0].email ? loginUser[0].email : "maneesh.kumar@mtwfs.com";
        // let loginDetails = loginUser && loginUser[0] ? loginUser[0].role_name : "" ;
        let pickRole = userImages.find(r => r.name === userData.role_name);        
        let userProfileAuthFeature = authService.getFeature("/myinformationedit");
        let userNotificationAuthFeature = authService.getFeatureByFeatureName(fConsts.Notifications);
        let userRoleImage="";
        if (pickRole != null) {
             userRoleImage = pickRole.img;
        }        
        else if(pickRole === undefined)
		{
			userRoleImage = require("../../images/users/user.png");
        }   

        
        let phonenumandcountrycode=null;
		if(this.state.countryCodeWithoutPlus)		
            phonenumandcountrycode= (<li x-ms-format-detection="none">{"+" + this.state.countryCodeWithoutPlus}&nbsp;{userData.phone_number}</li>)
        
        return (
            <React.Fragment>
                <div className={hidden ? "navUserWrapper showLogoutModal" : "navUserWrapper"}>
                    <div className="treeinfoView">
                        <div class="loadingProgress">
                            <div class="loadingProgressIn"></div>
                        </div>
                        <div class="userDetailsDiv">
                            <div class="userProfile">
                                <h4>My Account
                                {/* <Link to="/myinformationedit" style={ (userNotificationAuthFeature && userNotificationAuthFeature.is_editable === 1) ? { display: 'block' } : { display: 'none' }} class="btn profileEdit disabled">
                                        <img src={require('../../images/icons/penB.svg')} alt="My Account Edit" title="My Account Edit" />
                                    </Link> */}
                                </h4>
                                <div class="userDetails">
                                    <div class="userProfileImg">
                                        <img  alt="" src={userRoleImage} />
                                    </div>
                                    <div class="userNameRole">
                                        <span class="userName">{loggedinUser}</span>
                                        <span class="userRole">{loginRoleName}</span>
                                    </div>
                                </div>
                                <ul class="userDetailsList">
                                    <li>{this.state.emailfromTokan}</li>                                    
                                    {phonenumandcountrycode}
                                    <li>&nbsp;</li>
                                    <li>{userData.street_name} {userData.street_number}</li>
                                    <li>{userData.zip_code} {userData.city}</li>
                                    <li>{userData.country}</li>
                                    <li>&nbsp;</li>
                                   {/* { ( (userProfileAuthFeature && userProfileAuthFeature.is_editable === 1)|| 
                                      (userNotificationAuthFeature && userNotificationAuthFeature.is_editable === 1) ||
                                      (userProfileAuthFeature && userProfileAuthFeature.is_view_only === 1) ||
                                      (userNotificationAuthFeature && userNotificationAuthFeature.is_view_only ===1) ) &&  <li style={this.props.hideViewAccBtn ? { display: 'none' } : { display: 'block' }} ><Link to="/myInformation" title="View Account" alt="View Account" class="viewAccount">View Account</Link></li> } */}
                                    <li class="emptyLI">&nbsp;</li>
                                </ul> 
                                <div className="modal-scroll-tip"></div>
                            </div>                          
                            <button onClick={this.hideUserDetails} className="btn btn-secondary signOutBtn" type="button" data-toggle="modal" data-target="#SignOutModal"><span className="signOutBtnIcon"></span>Sign Out</button>
                        </div>   
                    </div>
                </div>
                <div className={showsignOut ? "modal fade SignOutModal show" : "modal fade SignOutModal"} data-keyboard="true" data-backdrop="false" id="SignOutModal" tabindex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
                    <div className="modal-dialog modal-md loginModal">
                        <div className="modal-content">
                            <div className="modal-header loginModalHeader modal-header-danger">
                                Sign Out
                        </div>
                            <div class="modal-body">
                                <div class="singOutConfirmation">
                                    Are you sure you want to sign out {this.state.emailfromTokan}?
                            </div>
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button">Cancel</button>
                                {/* <button class="btn btn-secondary signOutYesBtn" onClick={() => this.nextPath('/')} type="button">Sign Out</button> */}
                                <Link to='/' onClick={(e) => this.signoutForm(e)} class="btn btn-secondary signOutYesBtn">Sign Out</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};
function mapStateToProps(state) {
    return {
        filterKey: [],
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ logOutApplication }, dispatch);			
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutForm);

const userProfileMockData = {
    "user": {
        "name": "",
        "Id": "1",
        "phone_number": "",
        "email": "",
        "street_name": "",
        "street_number": "",
        "zip_code": "",
        "city": "",
        "country": "",
        "state": ""
    }
}