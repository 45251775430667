import React, { useReducer, useEffect } from "react";
import moment from "moment";
import $ from 'jquery';
import {
    RequestTypeSelector,
    CDatePicker, MyApprovalGridRow, ApprovalButtonGroup
} from ".";
import GoToPagination from "../goToPagination";
import { PageLoader, Section } from "../commonControls";
import { TableHeader } from "../tableHeader";
import { BaseService, ApprovalWorkflow } from '../../service/api';
import http from '../../service/httpService';
import authService from '../../service/authService';
import RequestDetail from "./requestDetail/RequestDetail";

import {
    initialState,
    myApprovalReducer,
    requestTypeChange,
    dateChange,
    sortData,
    pageChange,
    loadData,
    showDetail,
    hideDetail,
    itemSelect,
    showApprovalModel,
    hideApprovalModel,
    submitApproval,
    showPageLoader
} from '../../reducers/myApprovalReducer';

import ApprovalModal from "./ApprovalModel";

const userAuthFeature = authService.getFeature("/dashboardPendingApproval");

let columns = [
    { path: "requestId", label: "#" },
    { path: "requestType.type", label: "Request Type" },
    { path: "dateCreated", label: "Date Created" },
    { path: "comment", label: "Request Comments" },
    { path: "createdByEmail", label: "Submitted By" },
    { path: "", label: "", isCheckBox: true, isCheckBoxActive: userAuthFeature.is_editable }
];

const formatDate = (date) => date ? moment(new Date(date)).format("YYYY-MM-DD") : null;

const ApprovalList = ({ active }) => {

    const [state, dispatch] = useReducer(myApprovalReducer, initialState);
    const checkedReqType = false;

    useEffect(() => {
        document.body.style.background = "#f2f2f2";
        $("html").removeAttr("style");
        if (state.loading) {
            getApprovals();
        }

        window.addEventListener('resize', updateDimensions);
    }, [state.loading])

    useEffect(() => {
        updateDimensions();
    })

    const updateDimensions = () => {
        const windowHeight = $(window).height();
        const $tableBody = $('.dashboardTableSrlBar');
        const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }

    const getApprovals = async () => {
        let { startDate, endDate } = state;
        const loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        startDate = formatDate(startDate);
        endDate = formatDate(endDate);
        const payload = {
            type: "showApprovals",
            useremail: loginUser.email,
        }
        if (state.requestStatus) {
            payload["statusFilter"] = state.requestStatus.id;
        }
        if (startDate && endDate) {
            payload["startDate"] = startDate;
            payload["endDate"] = endDate;
        }
        const pageNum = state.resetPage ? 1 : state.currentPage;
        let apiUrl = `${BaseService.root}${ApprovalWorkflow.userRequestList}`;
        const pageNumber = `pageNum=${pageNum}`;
        const pageLimit = `pageSize=${state.pageSize}`;
        const sortOrder = `sortOrder=${state.sortColumn.order}&sortBy=${state.sortColumn.path}`
        const url = `${apiUrl}?${pageNumber}&${pageLimit}&${sortOrder}`;
        try {
            const httpResponse = await http({ method: 'post', url, data: payload });
            if (httpResponse && httpResponse.data) {
                const { response, numberOfPages } = httpResponse.data.data;
                const responseList = response.map(item => { return { ...item, actionName: 'Action', checked: false } });
                dispatch(loadData({ approvalList: responseList, totalPages: numberOfPages, currentPage: pageNum }))
            }
        } catch (error) {
            dispatch(loadData({ approvalList: [], totalPages: 0, currentPage: pageNum }))
        }

    }


    //Reload page only if start date and end date both are selected,
    const dateChangeHandler = (field, value) => {
        const _start_end_date = {
            startDate: state.startDate,
            endDate: state.endDate,
            [field]: value
        };
        let loading = false;
        if (_start_end_date.startDate && _start_end_date.endDate) {
            loading = true;
        }
        dispatch(dateChange({ field, value, loading }))
    }

    const showRequestDetail = async (item) => {
        dispatch(showDetail({ ...item, title: item.requestType.type }))
    }

    const onItemSelectHandler = (item) => {
        const approvals = [...state.approvalList];
        const index = approvals.findIndex(entry => entry.requestId == item.requestId);
        approvals[index].checked = !approvals[index].checked;
        dispatch(itemSelect(approvals));
    }

    const selectAllHandler = (e) => {
        const approvals = [...state.approvalList];
        approvals.forEach(item => item.checked = e.target.checked)
        dispatch(itemSelect(approvals));
    }

    const handleApprovalAction = async (comment) => {
        const selectedApprovals = state.approvalList
            .filter(item => item.checked)
            .map(item => item.requestId);

        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        const { email: loggedinUser } = loginUser;

        const payload = {
            "type": state.approvalType.name,
            "approverEmail": loggedinUser,
            "requestIds": selectedApprovals,
            "approverComment": comment
        }
        try {
            dispatch(showPageLoader(true))
            await http({
                method: 'post',
                url: `${BaseService.root}${ApprovalWorkflow.userEventPost}`,
                data: payload
            });
            dispatch(submitApproval());
        } catch (error) {
            dispatch(hideApprovalModel());
        }
    }

    return (
        <>
            <PageLoader size='lg' loading={state.pageLoader}/>
            <Section className="actionsPermissionsHeader">
                <ul>
                    <li>
                        <Section className="dateRangeFilter">
                            <CDatePicker label="From" className="fromDateDiv"
                                selected={state.startDate}
                                onChange={(date) => dateChangeHandler('startDate', date)}
                                minDate={state.minDate}
                                maxDate={state.maxDate} />

                            <CDatePicker label="To" className="toDateDiv"
                                selected={state.endDate}
                                onChange={(date) => dateChangeHandler('endDate', date)}
                                minDate={state.minDate}
                                maxDate={state.maxDate} />
                        </Section>
                        <RequestTypeSelector onChange={(type) => dispatch(requestTypeChange(type))} />
                    </li>
                    <li>
                        <ApprovalButtonGroup
                            selectAll={state.selectAll}
                            referAllowed={state.referAllowed}
                            onClick={(approvalType) => dispatch(showApprovalModel(approvalType))}
                        />
                    </li>
                </ul>
            </Section>
            <Section className="actionsPermissionsTable">
                <TableHeader
                    sortColumn={state.sortColumn}
                    onSort={(sortColumn) => dispatch(sortData(sortColumn))}
                    isIndeterminate={state.selectAll === 0}
                    selectAllChange={(e) => selectAllHandler(e)}
                    columns={columns}
                    isSelectAll={state.selectAll === 1} />
            </Section>
            <Section className="dashboardTableSrlBar">
                <Section className="actionsPermissionsTable">
                    <MyApprovalGridRow
                        loading={state.loading}
                        list={state.approvalList}
                        rowNumber={state.rowNumber}
                        onItemSelect={item => onItemSelectHandler(item)}
                        onShowDetail={showRequestDetail} />
                </Section>
            </Section>

            <GoToPagination
                totalPageNumber={state.totalPages}
                pageNumber={state.currentPage}
                navigateTo={(p) => dispatch(pageChange(p))}
            />

            {/* <PendingApprovalModal open={requeststatusmodal.open} requestObject={requeststatusmodal.requestObject} downloadUnitInventory={(e) => this.reviewUnitInventory(e)} onSuccessModel={() => this.onSuccessCloseModal()} />*/}
            <ApprovalModal
                open={state.openApprovalModel}
                approvalType={state.approvalType}
                onClose={() => dispatch(hideApprovalModel())}
                onSubmit={(comment) => handleApprovalAction(comment)} />
            
            <RequestDetail 
            open={state.showDetail}
            onClose={() => dispatch(hideDetail())}
            requestObject={state.requestDetail}
            editable
            />

        </>
    )
}

export default ApprovalList;
