import React from 'react';

const ApprovalButtonGroup = ({selectAll, referAllowed, onClick}) => {

    const className = selectAll > -1 ? "btn btn-dark" : "btn btn-dark disabled";

    return (
        <>
            <button type="button" className={className} title="Approve" onClick={() => onClick('approveRequest')}>Approve</button>
            <button type="button" className={className} title="Reject" onClick={() => onClick('rejectRequest')}>Reject</button>
            <button type="button" className={`${className} ${!referAllowed ? 'disabled':''}`} title="Refer Back" onClick={()=>onClick('referBackRequest')}>Refer Back</button>
        </>
    )
}

export default ApprovalButtonGroup;
