import React from 'react';
import DatePicker from 'react-datepicker';


const CDatePicker = ({ label, className, ...rest }) => {
    
    const resetDatepicker = (e) => {
        e.preventDefault();
        return false;
    }

    return (
        <div className={className}>
            <span className="formLabelText">{label}</span>
            <DatePicker
                type="text"
                class="form-control dayDatepicker"
                placeholderText="MM/DD/YYYY"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onKeyDown={(e) => resetDatepicker(e)}
                {...rest}
            />
        </div>
    )
}

export default CDatePicker;