// Pagination GoTo component - Functional component
import React, { useEffect, useState } from 'react';

// Initial props declared with default value
export default function GoToPagination({
    isBlackBackground = false,
    totalPageNumber = 1,
    perPageCount = 2,
    pageNumber = 1,
    navigateTo
}) {
    // Declared the states needed for this component
    const [currentPage, setCurrentPage] = useState(pageNumber)
    const [goToPageNumber, setGoToPageNumber] = useState('')
    const [pageArray, setPageArray] = useState([])
    const [hasNextPage, setNextPage] = useState(false)
    const [hasPreviousPage, setPrevPage] = useState(false)

    // Initial component rendering calculation is done in this use effect.
    useEffect(() => {
        processFlow(pageNumber);
    }, [totalPageNumber, pageNumber]);

    // Main function used to calculate the logic of pagination
    // This function will set the current page , total page and page number need to show.
    const processFlow = (currentPageParam) => {
        let totalPages = Number(totalPageNumber);
        let hasPreviousPage = Number(currentPageParam) === 1 ? false : true;
        let hasNextPage = totalPages && currentPageParam ? Number(currentPageParam) === Number(totalPages) ? false : true : false;

        let last = (Number(currentPageParam) + (Number(totalPages) > 5 ? Number(perPageCount) : 5)) - 1;

        let pageArray = new Array();
        const limitSet = Number(totalPageNumber) > 5 ? Number(currentPageParam) > 3 ? Number(currentPageParam) > 1 ? Number(currentPageParam) - 1 : Number(currentPageParam) : 1 : 1 ;

        let i = limitSet;
        for (i = limitSet; i <= last; i++)pageArray.push(i)

        setCurrentPage(currentPageParam);
        setPageArray(pageArray);
        setPrevPage(hasPreviousPage);
        setNextPage(hasNextPage);
    }

    // onclick function for the page number, after current page is set need to recalculate the logic and navigateTo is triggered
    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        processFlow(pageNumber);
        if (navigateTo) {
            navigateTo(pageNumber);
        }
    }

    const IsAlphaNumeric = (e) => {
        e.target.value = e.target.value.trim().replace(/[^\d]/g, '');
    }

    // goTo input field function
    const handleKeyPress = (e) => {
        if (Number(e.target.value) !== 0 && Number(e.target.value) > totalPageNumber) {
            return;
        }
        // This is perfectly safe in react, it correctly detect the keys
        if ((e.key == 'Enter') || (e.which === 13)) {
            const keyValue = Number(e.target.value);
            handleClick(keyValue);
            setGoToPageNumber('');
            document.activeElement.blur();
        }
    }

    // render method 
    return (
        totalPageNumber && totalPageNumber > 1 ? <div className={isBlackBackground ? "paginationDWgoto" : "paginationWgoto"}>
            {hasPreviousPage && <button className="btn prevBtn" onClick={() => handleClick(currentPage - 1)} type="button">&nbsp;</button>}
            {pageArray.includes(1) || pageArray.includes(2) ? null : <a href="#" onClick={(e) => {e.preventDefault(); handleClick(1)}}>{1}</a>}
            {pageArray.includes(1) || pageArray.includes(2) ? null : <span>...</span>}
            {pageArray && pageArray.length && pageArray.map((value, index) =>
                value <= totalPageNumber && <a href="#" key={value + index} onClick={(e) => {e.preventDefault(); handleClick(value)}} className={value == currentPage ? "active" : ""}>{value}</a>
            )}
            {pageArray.includes(totalPageNumber)|| pageArray.includes(totalPageNumber - 1) ? null : <span>...</span>}
            {pageArray.includes(totalPageNumber) ? null : <a href="#" onClick={(e) => {e.preventDefault(); handleClick(totalPageNumber)}}>{totalPageNumber}</a>}
            {hasNextPage && <button className="btn nextBtn" onClick={() => handleClick(currentPage + 1)} type="button">&nbsp;</button>}
            {totalPageNumber > 5 && <div className="gotoPage">Go to: <input id="goto" className="form-control" type="number" onKeyDown={handleKeyPress} onKeyUp={IsAlphaNumeric} value={goToPageNumber} onChange={(e) => {
                if (e && e.target && e.target.value && (e.target.value.length > totalPageNumber.length || Number(e.target.value) > totalPageNumber || Number(e.target.value) === 0)) {
                    return;
                }
                setGoToPageNumber(e.target.value);
            }
            } /></div>}
        </div> : null
    )
}


