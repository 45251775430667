import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import { DATE_FORMAT_VALUE, REVIEW_UNIT_INVENTORY, REVIEW_UNIT_MESSAGE } from '../../utils/appConstants';
import moment from 'moment';
import _ from 'lodash';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};
class RequestStatusModal extends Component {

    nextPath(path) {
        this.props.history.push(path);
    }
    constructor(props) {
        super(props);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    state = {
        open: false
    };

    onCloseModal = (e) => {
        this.props.onSuccessModel();
    };

    editUserDetails = () => {
        this.props.editUserDetails();
    }

    downloadUnitInventory = () => {
        this.props.downloadUnitInventory();
    }

    render() {
        const { open, requestObject } = this.props;
        return (
            <div style={styles}>
                {requestObject && requestObject.requestType ?
                    <Modal open={open} showCloseIcon={false} center onClose={(e) => this.onCloseModal(e)}>
                        <div className="modal fade show" data-keyboard="true" data-backdrop="static" id="SignInSuccessModal" style={{ display: "block" }}>
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{((requestObject.statusDetails.currentStatus === "Re-Submit") ? "Refer Back" : requestObject.statusDetails.currentStatus)} </h5>
                                        <button type="button" className="close" data-dismiss="modal" onClick={this.onCloseModal}>&nbsp;</button>
                                    </div>
                                    {(requestObject.requestType === "Add User" || requestObject.requestType === "Edit User") && requestObject.statusDetails ?
                                        (<div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                            {requestObject.statusDetails.requestDetails ?
                                                <div className="pendingApprovalsTableExp">
                                                    <ul>
                                                        <li>User Name</li>
                                                        <li>{requestObject.statusDetails.requestDetails.username}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>User Role</li>
                                                        <li>{requestObject.statusDetails.requestDetails.roleName}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Email</li>
                                                        <li>{requestObject.statusDetails.requestDetails.email}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Phone Number</li>
                                                        <li>{requestObject.statusDetails.requestDetails.country_code} {requestObject.statusDetails.requestDetails.phoneNumber}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Country</li>
                                                        <li>{requestObject.statusDetails.requestDetails.country}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Assigned Customer Groups</li>
                                                        <li>{_.join(_.map(requestObject.statusDetails.requestDetails.groups, item => item.groupName), ', ')}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Comment</li>
                                                        <li>{requestObject.statusDetails.requestDetails.comment}</li>
                                                    </ul>
                                                    <ul>
                                                        <li>Created By</li>
                                                        <li>{requestObject.statusDetails.requestDetails.requesterEmail}<span>{requestObject.statusDetails.requestDetails.requesterRoleName}</span></li>
                                                    </ul>
                                                </div> : null}

                                            <div className="myRequestTableExp">
                                                <ul className="tHead">
                                                    <li>Level</li>
                                                    <li>User</li>
                                                    <li>Status</li>
                                                    <li>Comments</li>
                                                    <li>Last Action On</li>
                                                </ul>
                                                {requestObject.statusDetails.events.map((item, i) =>
                                                    <ul className="tBody" key={i}>
                                                        <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                        <li>{item.email}</li>
                                                        <li>{item.status}</li>
                                                        <li>{item.comment}</li>
                                                        <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                    </ul>)}

                                            </div>

                                        </div>) : (requestObject.requestType === "Add Unit Inventory" || requestObject.requestType === "Edit Unit Inventory" || requestObject.requestType === "Delete Unit Inventory" || requestObject.requestType === "Deprovisioning Unit") && requestObject.statusDetails ?
                                            <div className="modal-body">
                                                {requestObject.statusDetails && requestObject.statusDetails.requestDetails ?
                                                    <div class="inventoryModalTable">
                                                        <ul class="tHead">
                                                            <li>#</li>
                                                            <li>Serial Number</li>
                                                            <li>Brand</li>
                                                            <li>Model Name</li>
                                                            <li>Registration Code</li>
                                                        </ul>
                                                        <ul class="tBody">
                                                            <li>{requestObject.statusDetails.requestDetails && requestObject.statusDetails.requestDetails.ID ? 1 : 1}</li>
                                                            <li>{requestObject.statusDetails.requestDetails && requestObject.statusDetails.requestDetails.SERIALNO ? requestObject.statusDetails.requestDetails.SERIALNO : "NA"}</li>
                                                            <li>{requestObject.statusDetails.requestDetails && requestObject.statusDetails.requestDetails.BRANDNAME ? requestObject.statusDetails.requestDetails.BRANDNAME : "NA"}</li>
                                                            <li>{requestObject.statusDetails.requestDetails && requestObject.statusDetails.requestDetails.MODELNAME ? requestObject.statusDetails.requestDetails.MODELNAME : "NA"}</li>
                                                            <li>{requestObject.statusDetails.requestDetails && requestObject.statusDetails.requestDetails.REGISTRATION_CODE ? requestObject.statusDetails.requestDetails.REGISTRATION_CODE : "NA"}</li>
                                                        </ul>
                                                    </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                {/* {requestObject.statusDetails && requestObject.statusDetails.requestDetails && (requestObject.statusDetails.requestDetails).length > 5 ?  */}
                                                <div class="buttonAlignCenter">
                                                    <p>{REVIEW_UNIT_MESSAGE}</p>
                                                    <button class="btn btn-dark" type="button">{REVIEW_UNIT_INVENTORY}</button>
                                                </div> 
                                                {/* : null} */}
                                                <div className="myRequestTableExp">
                                                    <ul className="tHead">
                                                        <li>Level</li>
                                                        <li>User</li>
                                                        <li>Status</li>
                                                        <li>Comments</li>
                                                        <li>Last Action On</li>
                                                    </ul>
                                                    {requestObject.statusDetails.events.map((item, i) =>
                                                        <ul className="tBody">
                                                            <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                            <li>{item.email}</li>
                                                            <li>{item.status}</li>
                                                            <li>{item.comment}</li>
                                                            <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                        </ul>)}

                                                </div></div> : (requestObject.requestType === "Add Bulk Unit Inventory" || requestObject.requestType === "Edit Unit Bulk Inventory" || requestObject.requestType === "Delete Unit Bulk Inventory") && requestObject.statusDetails ?
                                                <div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                    {requestObject.statusDetails && requestObject.statusDetails.requestDetails ?
                                                        <div class="inventoryModalTable">
                                                            <ul class="tHead">
                                                                <li>#</li>
                                                                <li>Serial Number</li>
                                                                <li>Brand</li>
                                                                <li>Model Name</li>
                                                                <li>Registration Code</li>
                                                            </ul>
                                                            {requestObject.statusDetails.requestDetails.map((item, i) =>
                                                                (
                                                                    <React.Fragment key={i}>
                                                                        <ul className="tBody">
                                                                            <li>{i + 1}</li>
                                                                            <li>{item.SERIALNO ? item.SERIALNO : "NA"}</li>
                                                                            <li>{item.BRANDNAME ? item.BRANDNAME : "NA"}</li>
                                                                            <li>{item.MODELNAME ? item.MODELNAME : "NA"}</li>
                                                                            <li>{item.REGISTRATION_CODE ? item.REGISTRATION_CODE : "NA"}</li>
                                                                        </ul>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                    {requestObject.statusDetails && requestObject.statusDetails.requestDetails && (requestObject.statusDetails.requestDetails).length >= 5 ? <div class="buttonAlignCenter">
                                                        <p>{REVIEW_UNIT_MESSAGE}</p>
                                                        <button class="btn btn-dark" type="button" onClick={(requestObject) => this.props.downloadUnitInventory(requestObject)}>{REVIEW_UNIT_INVENTORY}</button>
                                                    </div> : null}
                                                    <div className="myRequestTableExp">
                                                        <ul className="tHead">
                                                            <li>Level</li>
                                                            <li>User</li>
                                                            <li>Status</li>
                                                            <li>Comments</li>
                                                            <li>Last Action On</li>
                                                        </ul>
                                                        {requestObject.statusDetails.events.map((item, i) =>
                                                            <ul className="tBody">
                                                                <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                                <li>{item.email}</li>
                                                                <li>{item.status}</li>
                                                                <li>{item.comment}</li>
                                                                <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                            </ul>)}

                                                    </div></div> :
                                                (requestObject.requestType === "Add Brand" || requestObject.requestType === "Edit Brand" || requestObject.requestType === "Delete Brand") && requestObject.statusDetails ?
                                                    <div class="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                        {requestObject.statusDetails && requestObject.statusDetails.requestDetails ?
                                                            <div class="pendingApprovalsTableExp">
                                                                <ul>
                                                                    <li>Brand Name</li>
                                                                    <li>{requestObject.statusDetails.requestDetails.brandName ? requestObject.statusDetails.requestDetails.brandName : "NA"}</li>
                                                                </ul>
                                                                <ul>
                                                                    <li>Brand Logo</li>
                                                                    <li><span class="brandImg">{requestObject.statusDetails.requestDetails.logoUrl ? <img src={requestObject.statusDetails.requestDetails.logoUrl} alt="" /> : ""}</span></li>
                                                                </ul>
                                                                <ul>
                                                                    <li>Comment</li>
                                                                    <li>{requestObject.statusDetails.requestDetails.comment ? requestObject.statusDetails.requestDetails.comment : "NA"}</li>
                                                                </ul>
                                                            </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                        <div className="myRequestTableExp">
                                                            <ul className="tHead">
                                                                <li>Level</li>
                                                                <li>User</li>
                                                                <li>Status</li>
                                                                <li>Comments</li>
                                                                <li>Last Action On</li>
                                                            </ul>
                                                            {requestObject.statusDetails.events.map((item, i) =>
                                                                <ul className="tBody">
                                                                    <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                                    <li>{item.email}</li>
                                                                    <li>{item.status}</li>
                                                                    <li>{item.comment}</li>
                                                                    <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                                </ul>)}
                                                        </div>
                                                    </div> :
                                                    (requestObject.requestType === "Add Model" || requestObject.requestType === "Edit Model" || requestObject.requestType === "Delete Model") && requestObject.statusDetails ?
                                                        <div class="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                            {requestObject.statusDetails && requestObject.statusDetails.requestDetails ?
                                                                <div class="pendingApprovalsTableExp">
                                                                    <ul>
                                                                        <li>Brand Name</li>
                                                                        <li>{requestObject.statusDetails.requestDetails.brandName ? requestObject.statusDetails.requestDetails.brandName : "NA"}</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>Brand Type</li>
                                                                        <li>{requestObject.statusDetails.requestDetails.brandType ? requestObject.statusDetails.requestDetails.brandType : "NA"}</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>Model Family</li>
                                                                        <li>{requestObject.statusDetails.requestDetails.modelFamilyName ? requestObject.statusDetails.requestDetails.modelFamilyName : "NA"}</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>Model Name</li>
                                                                        <li>{requestObject.statusDetails.requestDetails.modelName ? requestObject.statusDetails.requestDetails.modelName : "NA"}</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>Unit Logo</li>
                                                                        <li><span class="modelImg">{requestObject.statusDetails.requestDetails.logoUrl ? <img src={requestObject.statusDetails.requestDetails.logoUrl} alt="" /> : ""}</span></li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>Comment</li>
                                                                        <li>{requestObject.statusDetails.requestDetails.comment ? requestObject.statusDetails.requestDetails.comment : "NA"}</li>
                                                                    </ul>
                                                                </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                            <div className="myRequestTableExp">
                                                                <ul className="tHead">
                                                                    <li>Level</li>
                                                                    <li>User</li>
                                                                    <li>Status</li>
                                                                    <li>Comments</li>
                                                                    <li>Last Action On</li>
                                                                </ul>
                                                                {requestObject.statusDetails.events.map((item, i) =>
                                                                    <ul className="tBody">
                                                                        <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                                        <li>{item.email}</li>
                                                                        <li>{item.status}</li>
                                                                        <li>{item.comment}</li>
                                                                        <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                                    </ul>)}
                                                            </div>

                                                        </div> :
                                                    (requestObject.requestType === "Activate Customer User" || requestObject.requestType === "Deactivate Customer User") && requestObject.statusDetails ?
                                                        <div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                            {requestObject.statusDetails && requestObject.statusDetails.requestDetails ?
                                                                <div className="pendingApprovalsTableExp">
                                                                    <ul>
                                                                        <li>Customer Name</li>
                                                                        <li className="disabled">{requestObject.statusDetails.requestDetails.customerName ? requestObject.statusDetails.requestDetails.customerName : "NA"}</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>User Name</li>
                                                                        <li className="disabled">{requestObject.statusDetails.requestDetails.name ? requestObject.statusDetails.requestDetails.name : "NA"}</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>User Role</li>
                                                                        <li className="disabled">{requestObject.statusDetails.requestDetails.roleName ? requestObject.statusDetails.requestDetails.roleName : "NA"}</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>Email</li>
                                                                        <li className="disabled">{requestObject.statusDetails.requestDetails.username ? requestObject.statusDetails.requestDetails.username : "NA"}</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>Phone Number</li>
                                                                        <li className="disabled">{requestObject.statusDetails.requestDetails.country_code} {requestObject.statusDetails.requestDetails.phone_number}</li>
                                                                    </ul>
                                                                    <ul>  
                                                                        <li>Activation Status</li>
                                                                        <li>{requestObject.statusDetails.requestDetails.is_active === 0 ?"deactivated" :"activated"}</li> 
                                                                    </ul>
                                                                    {/* <ul>
                                                                        <li>Created By</li>
                                                                        <li className="disabled">{requestObject.statusDetails.requestDetails.requesterEmail}<span>{requestObject.statusDetails.requestDetails.requesterRoleName}</span></li>
                                                                    </ul> */}
                                                                </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                            <div className="myRequestTableExp">
                                                                <ul className="tHead">
                                                                    <li>Level</li>
                                                                    <li>User</li>
                                                                    <li>Status</li>
                                                                    <li>Comments</li>
                                                                    <li>Last Action On</li>
                                                                </ul>
                                                                {requestObject.statusDetails.events.map((item, i) =>
                                                                    <ul className="tBody">
                                                                        <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                                        <li>{item.email}</li>
                                                                        <li>{item.status}</li>
                                                                        <li>{item.comment}</li>
                                                                        <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                                    </ul>)}
                                                            </div>

                                                        </div> :
                                                        (requestObject.requestType === "Add IOT Software" || requestObject.requestType === "Add Controller Software" || requestObject.requestType === "Edit Controller Software" || requestObject.requestType === "Edit IOT Software" || requestObject.requestType === "Delete IOT Software") && requestObject.statusDetails ?
                                                            <div class="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                                {requestObject.statusDetails && requestObject.statusDetails.requestDetails ?
                                                                    <div class="pendingApprovalsTableExp">
                                                                        <ul>
                                                                            <li>Brand Name</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.brandName ? requestObject.statusDetails.requestDetails.brandName : "NA"}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Model Family</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.modelFamilyNames ? _.join(_.map(requestObject.statusDetails.requestDetails.modelFamilyNames, item => item), ', ') : "NA"}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Model Name</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.modelNames ? _.join(_.map(requestObject.statusDetails.requestDetails.modelNames, item => item), ', ') : "NA"}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Software Update</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.fileName ? requestObject.statusDetails.requestDetails.fileName : "NA"}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Software Version</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.version ? requestObject.statusDetails.requestDetails.version : "NA"}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Release Notes</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.description ? requestObject.statusDetails.requestDetails.description : "NA"}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Customer Group Name</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.customersGroupDetails.length} ({requestObject.statusDetails.requestDetails.customersGroupDetails ? _.join(_.map(requestObject.statusDetails.requestDetails.customersGroupDetails, item => item.short_name ? item.name + "(" + item.short_name + ")": item.name ), ', ') : "NA"})</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Comment</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.comment ? requestObject.statusDetails.requestDetails.comment : "NA"}</li>
                                                                        </ul>
                                                                    </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                                <div className="myRequestTableExp">
                                                                    <ul className="tHead">
                                                                        <li>Level</li>
                                                                        <li>User</li>
                                                                        <li>Status</li>
                                                                        <li>Comments</li>
                                                                        <li>Last Action On</li>
                                                                    </ul>
                                                                    {requestObject.statusDetails.events.map((item, i) =>
                                                                        <ul className="tBody">
                                                                            <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                                            <li>{item.email}</li>
                                                                            <li>{item.status}</li>
                                                                            <li>{item.comment}</li>
                                                                            <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                                        </ul>)}
                                                                </div>

                                                            </div> :
                                                            <div className="modal-body" style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                                                                {requestObject.statusDetails.requestDetails ?
                                                                    <div className="pendingApprovalsTableExp">
                                                                        <ul>
                                                                            <li>Customer Admin Name</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.admin_user_name}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Customer Name</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.customerName}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Location</li>
                                                                            <li>{`${requestObject.statusDetails.requestDetails.streetNumber}, ${requestObject.statusDetails.requestDetails.streetName},`} {requestObject.statusDetails.requestDetails.city ? requestObject.statusDetails.requestDetails.city + ',' : ''}  {requestObject.statusDetails.requestDetails.zip_code ? requestObject.statusDetails.requestDetails.zip_code + ',' : ''} {requestObject.statusDetails.requestDetails.country}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>Start Date</li>
                                                                            <li>{moment(new Date(requestObject.statusDetails.requestDetails.subsCriptionStartDate)).format(DATE_FORMAT_VALUE)}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>End Date</li>
                                                                            <li>{moment(new Date(requestObject.statusDetails.requestDetails.subsCriptionEndDate)).format(DATE_FORMAT_VALUE)}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>KC3.0 Offering</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.subscribedFeatures && _.join(_.map(requestObject.statusDetails.requestDetails.subscribedFeatures[0].features, item => item.feature_name), ', ')}</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li>C2C API Offering</li>
                                                                            <li>{requestObject.statusDetails.requestDetails.subscribedFeatures && requestObject.statusDetails.requestDetails.subscribedFeatures[1] && _.join(_.map(requestObject.statusDetails.requestDetails.subscribedFeatures[1].features, item => item.feature_name), ', ')}</li>
                                                                        </ul>
                                                                    </div> : <h6 className="norecordsfoundbluetext">No records found.</h6>}
                                                                <div className="myRequestTableExp">
                                                                    <ul className="tHead">
                                                                        <li>Level</li>
                                                                        <li>User</li>
                                                                        <li>Status</li>
                                                                        <li>Comments</li>
                                                                        <li>Last Action On</li>
                                                                    </ul>
                                                                    {requestObject.statusDetails.events.map((item, i) =>
                                                                        <ul className="tBody">
                                                                            <li>{(item.level === "Requested") ? "Requestor" : item.level}</li>
                                                                            <li>{item.email}</li>
                                                                            <li>{item.status}</li>
                                                                            <li>{item.comment}</li>
                                                                            <li>{item.date ? moment(new Date(item.date)).format(DATE_FORMAT_VALUE) : ""}</li>
                                                                        </ul>)}

                                                                </div></div>}
                                    {requestObject.statusDetails.currentStatus === "Re-Submit" ?
                                        <div className="modal-footer textAlignCenter">
                                            <button className="btn btn-dark" type="button" onClick={(requestObject) => this.props.editUserDetails(requestObject)}>{(requestObject.requestType === "Add User" || requestObject.requestType === "Edit User") ? "Edit User" : ((requestObject.requestType === "Add Customer" || requestObject.requestType === "Edit Customer") ? "Edit Customer" : ((requestObject.requestType === "Add IOT Software" || requestObject.requestType === "Edit IOT Software") ? "Edit IOT Software" : ((requestObject.requestType === "Add Controller Software" || requestObject.requestType === "Edit Controller Software") ? "Edit Controller Software" : ((requestObject.requestType === "Add Brand" || requestObject.requestType === "Edit Brand") ? "Edit Brand" : ((requestObject.requestType === "Add Model" || requestObject.requestType === "Edit Model") ? "Edit Model" : ((requestObject.requestType === "Deprovisioning Unit") ? "Certificate Management List" : ((requestObject.requestType === "Activate Customer User" || requestObject.requestType === "Deactivate Customer User") ? "Customer User Management List" : ((requestObject.requestType === "Add Unit Inventory" || requestObject.requestType === "Edit Unit Inventory") ? "Edit Unit Inventory" : "Inventory Management List"))))))))}</button>
                                        </div> : null}
                                </div>
                            </div>
                        </div>
                    </Modal> : null}
            </div>
        );
    }
}

export default RequestStatusModal