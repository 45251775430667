import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainDashboard from '../components/dashboard/MainDashboard';
import DashboardPendingApproval from '../components/dashboard/DashboardPendingApproval';
import MainMenu from '../components/adminmenus/mainMenu';

const NewLogin = React.lazy(() => import('../components/login/UserLogin'));
const SSOLogin = React.lazy(() => import('../components/login/SSOLogin'));
const SSOLoginSuccess = React.lazy(() => import('../components/login/SSOLoginSuccess'));
const SignUp = React.lazy(() => import('../components/login/landingPage'));
const MyRequest = React.lazy(() => import('../components/actionspermissions/MyRequest'));
const PendingApproval = React.lazy(() => import('../components/actionspermissions/PendingApproval'));
const CustomerMgmtList = React.lazy(() => import('../components/adminmenus/customermanagement/CustomerMgmtList'));
const CustomerUserMgmtList = React.lazy(() => import('../components/adminmenus/customermanagement/CustomerUserMgmtList'));
const CustomerUserRoleMgmtList = React.lazy(() => import('../components/adminmenus/customermanagement/CustomerUserRoleMgmtList'));
const UserRoles = React.lazy(() => import('../components/adminmenus/customermanagement/UserRoles'));
const AddCustomer = React.lazy(() => import('../components/adminmenus/customermanagement/AddCustomer'));
const EditCustomer = React.lazy(() => import('../components/adminmenus/customermanagement/EditCustomer'));
const CustomerSubscription = React.lazy(() => import('../components/adminmenus/customermanagement/CustomerSubscription'));
const EditCustomerSubscription = React.lazy(() => import('../components/adminmenus/customermanagement/EditCustomerSubscription'));
const CustomerDashboard = React.lazy(() => import('../components/adminmenus/customermanagement/CustomerDashboard'));
const CustomerCertificateMgmtList = React.lazy(() => import('../components/adminmenus/customermanagement/CustomerCertificateMgmtList'));
const CustomerOrgMgmt = React.lazy(() => import('../components/adminmenus/customermanagement/CustomerOrgMgmt'));
const AdminUserList = React.lazy(() => import('../components/adminmenus/adminusermanagement/AdminUserList'));
const AdminAddUser = React.lazy(() => import('../components/adminmenus/adminusermanagement/AdminAddUser'));
const AdminAssignGroups = React.lazy(() => import('../components/adminmenus/adminusermanagement/AdminAssignGroups'));
const AdminEditUser = React.lazy(() => import('../components/adminmenus/adminusermanagement/AdminEditUser'));
const AdminRoleList = React.lazy(() => import('../components/adminmenus/adminusermanagement/AdminRoleList'));
const AdminRoleView = React.lazy(() => import('../components/adminmenus/adminusermanagement/AdminRoleView'));
const UserRole = React.lazy(() => import('../components/adminmenus/adminusermanagement/userRole'));
const UserRoleId = React.lazy(() => import('../components/adminmenus/adminusermanagement/userRole'));
const GroupMgmtList = React.lazy(() => import('../components/adminmenus/groupmanagement/GroupMgmtList'));
const AddGroup = React.lazy(() => import('../components/adminmenus/groupmanagement/AddGroup'));
const ViewGroup = React.lazy(() => import('../components/adminmenus/groupmanagement/ViewGroup'));
const EditGroup = React.lazy(() => import('../components/adminmenus/groupmanagement/EditGroup'));
const InventoryMgmtList = React.lazy(() => import('../components/adminmenus/inventorymanagement/InventoryMgmtList'));
const AddInventory = React.lazy(() => import('../components/adminmenus/inventorymanagement/AddInventory'));
const AddEaaSDevice = React.lazy(() => import('../components/adminmenus/inventorymanagement/AddEaaSDevice'));
const EditInventory = React.lazy(() => import('../components/adminmenus/inventorymanagement/EditInventory'));
const AddBulkInventory = React.lazy(() => import('../components/adminmenus/inventorymanagement/AddBulkInventory'));
const AddInventoryConfirmation = React.lazy(() => import('../components/adminmenus/inventorymanagement/AddInventoryConfirmation'));
const IOTAgentSoftwareList = React.lazy(() => import('../components/adminmenus/softwaremanagement/IOTAgentSoftwareList'));
const IOTAgentSoftwareUpload = React.lazy(() => import('../components/adminmenus/softwaremanagement/IOTAgentSoftwareUpload'));
const EditIOTAgentSoftwareUpload = React.lazy(() => import('../components/adminmenus/softwaremanagement/EditIOTAgentSoftwareUpload'));
const EditControllerSoftwareUpload = React.lazy(() => import('../components/adminmenus/softwaremanagement/EditControllerSoftwareUpload'));
const ControllerSoftwareUpload = React.lazy(() => import('../components/adminmenus/softwaremanagement/ControllerSoftwareUpload'));
const ControllerSoftwareList = React.lazy(() => import('../components/adminmenus/softwaremanagement/ControllerSoftwareList'));
const SoftwareCreation = React.lazy(() => import('../components/adminmenus/softwaremanagement/SoftwareCreation'));
const EditSoftwareCreation = React.lazy(() => import('../components/adminmenus/softwaremanagement/EditSoftwareCreation'));
const FryerReports = React.lazy(() => import('../components/adminmenus/adminreports/FryerReports'));
const ReportAssignUpdateList = React.lazy(() => import('../components/adminmenus/adminreports/ReportAssignUpdateList'));
const BrandMgmtList = React.lazy(() => import('../components/adminmenus/brandmanagement/BrandMgmtList'));
const AddBrand = React.lazy(() => import('../components/adminmenus/brandmanagement/AddBrand'));
const EditBrand = React.lazy(() => import('../components/adminmenus/brandmanagement/EditBrand'));
const SwaggerApiGroup = React.lazy(() => import('../components/adminmenus/swagger/SwaggerApiGroup'));
const SwaggerInterface = React.lazy(() => import('../components/adminmenus/swagger/SwaggerInterface'));
const SwaggerUIComponent = React.lazy(() => import('../components/adminmenus/swagger/SwaggerUIComponent'));
const MyInformation = React.lazy(() => import('../components/userinformation/MyInformation'));
const LogoutForm = React.lazy(() => import('../components/logout/LogoutForm'));
const ModelMgmtList = React.lazy(() => import('../components/adminmenus/modelmanagement/ModelMgmtList'));
const AddModel = React.lazy(() => import('../components/adminmenus/modelmanagement/AddModel'));
const EditModel = React.lazy(() => import('../components/adminmenus/modelmanagement/EditModel'));
const AdminChina = React.lazy(() => import('../components/adminmenus/admintestmanagement/AdminChina'));
const UnAuthorized = React.lazy(() => import('../components/login/unAuthorized'));
const underConstruction = React.lazy(() => import('../components/login/underConstruction'));
const ProtectedRoute = React.lazy(() => import('./productedRoute'));

const Routes = () => {
    return (
        <div>
            <div>
            <Suspense fallback={<p>Loading... </p>}>
                <Switch>
                    <Route exact path='/' component={NewLogin} />
                    <Route exact path='/welbiltsso' component={SSOLogin} />
                    <Route exact path='/ssoLoginSuccess' component={SSOLoginSuccess} />
                    <Route exact path='/signUp' component={SignUp} />
                    <ProtectedRoute exact path='/welbiltDashboard' component={MainDashboard} />
                    <ProtectedRoute exact path='/dashboardPendingApproval' component={DashboardPendingApproval} />
                    <Route exact path='/myRequest' component={MyRequest} />
                    <Route exact path='/pendingApproval' component={PendingApproval} />
                    {/* .....adminmenus .....*/}
                    <Route exact path='/mainMenu' component={MainMenu} />
                    {/* .....Customer Management .....*/}
                    <ProtectedRoute exact path='/customerMgmtList' component={CustomerMgmtList} />                        
                    <ProtectedRoute exact path='/customerUserMgmtList' component={CustomerUserMgmtList} />
                    <ProtectedRoute exact path='/customerUserRoleMgmtList' component={CustomerUserRoleMgmtList} />                        
                    <ProtectedRoute exact path='/userRoles' component={UserRoles} />
                    <ProtectedRoute exact path='/addCustomer' component={AddCustomer} />      
                    <ProtectedRoute exact path='/editCustomer' component={EditCustomer} />                    
                    <ProtectedRoute exact path='/customerSubscription' component={CustomerSubscription} />                        
                    <ProtectedRoute exact path='/EditCustomerSubscription' component={EditCustomerSubscription} />
                    <ProtectedRoute exact path='/customerDashboard' component={CustomerDashboard} /> 
                    <ProtectedRoute exact path='/customerCertificateMgmt' component={CustomerCertificateMgmtList} />
                    <Route exact path='/customerOrgMgmt' component={CustomerOrgMgmt} />
                    {/* .....Admin User Management .....*/}
                    <ProtectedRoute exact path='/adminUserMgmtList' component={AdminUserList} />
                    <ProtectedRoute exact path='/adminAddUser' component={AdminAddUser} />
                    <ProtectedRoute exact path='/adminAssignGroups' component={AdminAssignGroups} />                        
                    <ProtectedRoute exact path='/adminEditUser' component={AdminEditUser} />
                    <ProtectedRoute exact path='/adminUserMgmtRoleList' component={AdminRoleList} />
                    <ProtectedRoute exact path='/adminRoleView' component={AdminRoleView} />
                    <ProtectedRoute exact path='/userrole' component={UserRole} />
                    <Route exact path="/userrole/:roleId" component={UserRoleId} />
                    {/* .....Group Management ..... */}
                    <ProtectedRoute exact path='/groupMgmtList' component={GroupMgmtList} />
                    <ProtectedRoute exact path='/addGroup' component={AddGroup} />
                    <ProtectedRoute exact path='/viewGroup' component={ViewGroup} />
                    <Route exact path="/editGroup/:id" component={EditGroup} />
                    {/* .....Inventory Management .....*/}
                    <ProtectedRoute exact path='/inventoryManagement' component={InventoryMgmtList} />
                    <ProtectedRoute exact path='/addInventory' component={AddInventory} />                        
                    <ProtectedRoute exact path='/eaasManagement' component={AddEaaSDevice} />
                    <Route exact path="/editInventory/:serialNo/:brandId" component={EditInventory} />
                    <ProtectedRoute exact path='/addBulkInventory' component={AddBulkInventory} />
                    <ProtectedRoute exact path='/addInventoryConfirmation' component={AddInventoryConfirmation} /> 
                    {/* ..... Software Management .....*/}
                    <ProtectedRoute exact path='/agentSoftwareList' component={IOTAgentSoftwareList} />
                    <ProtectedRoute exact path='/agentSoftwareUpload' component={IOTAgentSoftwareUpload} />
                    <ProtectedRoute exact path='/editAgentSoftwareUpload' component={EditIOTAgentSoftwareUpload} />
                    <ProtectedRoute exact path='/editControllerSoftwareUpload' component={EditControllerSoftwareUpload} />                        
                    <ProtectedRoute exact path='/controllerSoftwareUpload' component={ControllerSoftwareUpload} />
                    <ProtectedRoute exact path='/controllerSoftwareList' component={ControllerSoftwareList} />
                    <ProtectedRoute exact path='/softwareCreation' component={SoftwareCreation} />
                    <ProtectedRoute exact path='/editSoftwareCreation' component={EditSoftwareCreation} />                        
                    {/* ..... Admin Reports .....*/}
                    <ProtectedRoute exact path='/fryerReports' component={FryerReports} />
                    <ProtectedRoute exact path='/reportAssignUpdateList' component={ReportAssignUpdateList} />
                    {/* .....Brand Management .....*/}
                    <ProtectedRoute exact path='/brandMgmtList' component={BrandMgmtList} />
                    <ProtectedRoute exact path='/addBrand' component={AddBrand} />
                    <ProtectedRoute exact path='/editBrand' component={EditBrand} />
                    <Route exact path='/swaggerApiGroup' component={SwaggerApiGroup} />
                    <Route exact path='/swaggerInterface' component={SwaggerInterface} />
                    <Route exact path='/swaggerUiComponent' component={SwaggerUIComponent} />
                    {/* .....User Information.... */}
                    <Route exact path='/myInformation' component={MyInformation} />
                    <Route exact path='/logoutForm' component={LogoutForm} />
                    {/* .....Model Management .....*/}
                    <ProtectedRoute exact path='/modelMgmtList' component={ModelMgmtList} />
                    <ProtectedRoute exact path='/addModel' component={AddModel} />
                    <ProtectedRoute exact path='/editModel' component={EditModel} />
                    {/* Admin CN */}
                    <ProtectedRoute exact path='/adminChina' component={AdminChina}/> 
                    <Route exact path="/un-authorized" component={UnAuthorized} />
                    <Route component={underConstruction} />
                </Switch>
             </Suspense> 
            </div>
        </div>
    )
}


export default Routes;
