import jwtDecode from "jwt-decode";
import http from './httpService';
import authHelper from '../utils/authHelper';
import authConstants from '../utils/authConstants';
import { setBrandId }  from '../utils/appConstants';
import { BaseService } from '../service/api';
import * as API from './api';

const { tokenKey, loginInfo, rememberMe, tokenExpireTime, allFeaturesKey, currentFeaturesKey }  = authConstants;

export const login = (postData, isRemember) => {
    return new Promise((resolve, reject)=> {
        
        http.post(API.BaseService.login, postData).then(response =>{
            let responseData = response.data;

            if(responseData.data !== undefined && responseData.data !== ''){ 	 

            var passwordVal = new Buffer(postData.password);
			var password_Encry = passwordVal.toString('base64');
			let encode_postData ={
				username:postData.username, 
				password:password_Encry,			
			}

            localStorage.setItem(loginInfo, JSON.stringify(responseData.data.user));          
            setJwt(responseData.data.token);
            
            let rolefeatures = responseData.data.features;
            localStorage.setItem(allFeaturesKey, JSON.stringify(rolefeatures));

            //Store BrandIds of the User && Customer Id
            let groupAccessBrand = responseData.data.groupAccess;
            let groupAccessCustomer = responseData.data.groupAccess;
            //User Story 63364: Remove brand group dependency in Admin Portal(Technical)
            localStorage.setItem(authConstants.userBrands, groupAccessBrand.brandIds ? JSON.stringify(groupAccessBrand.brandIds):[]);
            localStorage.setItem(authConstants.loginUserCustomers, JSON.stringify(groupAccessCustomer.customerIds));

            //Store defaultAdmin
            let defaultAdmin = responseData.data.role;
            localStorage.setItem("showDefaultAdmin", JSON.stringify(defaultAdmin.isDefaultAdmin));

           //Set Token
            setJwt(getJwt());  
            
            //Set Features
            let currentFeatures = authHelper.applyPermissions(rolefeatures);
            localStorage.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));

            isRemember && localStorage.setItem(rememberMe, isRemember);
            resolve(response);                    
           
           }
           else {
               reject(response);
           }
        }).catch(err=>{
            reject(err);
        });   
    }); 
}
// sso auth login
export const authTokenValidate = (accessToken) => {
    return new Promise((resolve, reject)=> {

        const hostname = window && window.location && window.location.hostname;
        let url = API.BaseService.loginAuthenticate;
        if (hostname === 'www.admin.welbiltconnect.cn' || hostname === 'admin.welbiltconnect.cn') {
            //For CHINA - PROD Environment
            url = API.BaseService.login_nx_sso;
        }

        http.post(url, accessToken).then(response =>{
            let responseData = response.data;

            if(responseData.data !== undefined && responseData.data !== ''){ 	 

            localStorage.setItem(loginInfo, JSON.stringify(responseData.data.user));          
            setJwt(responseData.data.token);
            
            let rolefeatures = responseData.data.features;
            localStorage.setItem(allFeaturesKey, JSON.stringify(rolefeatures));

            //Store BrandIds of the User && Customer Id
            let groupAccessBrand = responseData.data.groupAccess;
            let groupAccessCustomer = responseData.data.groupAccess;
            //User Story 63364: Remove brand group dependency in Admin Portal(Technical)
            localStorage.setItem(authConstants.userBrands, groupAccessBrand.brandIds ? JSON.stringify(groupAccessBrand.brandIds) : []);
            setBrandId(groupAccessBrand.brandIds ? groupAccessBrand.brandIds.join(',') : "");
            localStorage.setItem(authConstants.loginUserCustomers, JSON.stringify(groupAccessCustomer.customerIds));

            //Store defaultAdmin
            let defaultAdmin = responseData.data.role;
            localStorage.setItem("showDefaultAdmin", JSON.stringify(defaultAdmin.isDefaultAdmin));

           //Set Token
            setJwt(getJwt());  
            
            //Set Features
            let currentFeatures = authHelper.applyPermissions(rolefeatures);
            localStorage.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));

            resolve(response);                    
           
           }
           else {
               reject(response);
           }
        }).catch(err=>{
            reject(err);
        });   
    }); 
}

// sso auth login
export const usernameValidate = (username) => {
    return new Promise((resolve, reject)=> {
            
        http.post(API.BaseService.login_nx_sso, username).then(response =>{
            console.log("response",response);
	       let responseData = response.data;

            if(responseData.data !== undefined && responseData.data !== ''){ 	 

            localStorage.setItem(loginInfo, JSON.stringify(responseData.data.user));          
            setJwt(responseData.data.token);
            
            let rolefeatures = responseData.data.features;
            localStorage.setItem(allFeaturesKey, JSON.stringify(rolefeatures));

            //Store BrandIds of the User && Customer Id
            let groupAccessBrand = responseData.data.groupAccess;
            let groupAccessCustomer = responseData.data.groupAccess;
            //User Story 63364: Remove brand group dependency in Admin Portal(Technical)
            localStorage.setItem(authConstants.userBrands, groupAccessBrand.brandIds ? JSON.stringify(groupAccessBrand.brandIds) : []);
            setBrandId(groupAccessBrand.brandIds ? groupAccessBrand.brandIds.join(',') : "");
            localStorage.setItem(authConstants.loginUserCustomers, JSON.stringify(groupAccessCustomer.customerIds));

            //Store defaultAdmin
            let defaultAdmin = responseData.data.role;
            localStorage.setItem("showDefaultAdmin", JSON.stringify(defaultAdmin.isDefaultAdmin));

           //Set Token
            setJwt(getJwt());  
            
            //Set Features
            let currentFeatures = authHelper.applyPermissions(rolefeatures);
            localStorage.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));

            resolve(response);                    
           
           }
           else {
               reject(response);
           }
        }).catch(err=>{
            reject(err);
        });   
    }); 
}

export const setRememberMe = (isRemember) => {
    localStorage.setItem(rememberMe, isRemember);
}

export const getRememberMe = () =>{
    let isRemember = JSON.parse(localStorage.getItem(rememberMe));
    return isRemember;
}

export const getCurrentUser = () => {
    try {
      const jwt = getJwt();
      return jwtDecode(jwt);
    return jwt;
    } catch (ex) {
      return null;
    }
}

export const getJwt = () =>{
    return localStorage.getItem(tokenKey);
}

export const setJwt = (token) =>{ 
    try{ 
    localStorage.setItem(tokenKey, token);
    document.cookie = `${tokenKey}=${token}`; 
    let jwt = token && jwtDecode(token);
    if(jwt)
        localStorage.setItem(tokenExpireTime, jwt.exp);
    }
    catch{

    }    
}

export const deleteJwt = () =>
{
    localStorage.removeItem(tokenKey);
}

export const IsTokenTimeExipired = () =>{    
    let exp = localStorage.getItem(tokenExpireTime);
    if(exp){
    let expireTime = new Date(exp * 1000);
    let currentTIme = new Date();
    let isExpired = currentTIme > expireTime ;
    return isExpired;
    }
    else
      return false;   
}

/**
 * Store Current Features 
 * @param {*} serverFeatures 
 */
export const setFeatures = (rolefeatures) =>{
    let currentFeatures = authHelper.applyPermissions(rolefeatures);
    localStorage.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));
}

/**
 * Get Single Feature by route path
 * @param {*} route_path 
 */
export const getFeature = (route_path) =>{
    route_path = route_path.indexOf("userrole") !=-1 ? "/userrole" : route_path;
    let featuers = JSON.parse(localStorage.getItem(currentFeaturesKey));
    let authorizedFeature ={};
    if(featuers && featuers.length>0)
    for (let index = 0; index < featuers.length; index++) {
        let productedRoutes = featuers[index].productedRoutes;
        let routeAuthorized = productedRoutes.filter(f => f === route_path && featuers[index].feature_active === 1);
        if(routeAuthorized && routeAuthorized.length>0){
            authorizedFeature = featuers[index];
            break;
        }     
    };
    return authorizedFeature;
}

/**
 * Get all features by rendering Main Menu Apps.
 */
export const getAllFeatures = () =>{
    let featuers =JSON.parse(localStorage.getItem(currentFeaturesKey));
    let authorizedFeatures = featuers && featuers.filter(f => f.feature_active === 1);
    return authorizedFeatures;
}

export const clearLastSessionData = () =>{
    let cfeatuers = JSON.parse(localStorage.getItem(currentFeaturesKey));
    let allfeatuers = JSON.parse(localStorage.getItem(allFeaturesKey));   
    if(!cfeatuers || !allfeatuers){
       authHelper.deleteLocalSession();
     }
}

export const getLoginUserBrands = () =>{
    let userBrands = localStorage.getItem(authConstants.userBrands);
    userBrands = userBrands && JSON.parse(userBrands);
    return userBrands && userBrands.length >0 ? userBrands : [];
}

/**
 * Get Single Feature by Feature Name
 * @param {*} feature_name 
 */
export const getFeatureByFeatureName = (feature_name) =>{
    let features = JSON.parse(localStorage.getItem(currentFeaturesKey));
    let authorizedFeature ={};
    if(features && features.length>0)
    {
        authorizedFeature = features.find(f => f.feature_name === feature_name && f.feature_active === 1);
    }
    return authorizedFeature;
}

export default {
    login,
    authTokenValidate,
    getJwt,
    getCurrentUser,
    setRememberMe,
    getRememberMe,
    setJwt,
    deleteJwt,
    IsTokenTimeExipired,
    getFeature,
    getAllFeatures,
    clearLastSessionData,
    getLoginUserBrands,
    getFeatureByFeatureName,
    usernameValidate
};
 