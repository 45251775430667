import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import 'react-tooltip/dist/react-tooltip.css'

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

let browserHistory = BrowserRouter.browserHistory;

root.render(
  <Provider store={store} >
  <BrowserRouter history={browserHistory}>
    <App />
  </BrowserRouter>
</Provider>
);