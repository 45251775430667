import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
};

class ApprovalSubmitModal extends Component {

    nextPath(path) {
        this.props.history.push(path);
    }
    constructor(props) {
        super(props);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.state = {
            approveComment: "",
        }
    }

    state = {
        open: false
    };

    onCloseModal = (e) => {
        this.props.onSuccessModel();
    };

    cancelAction = () => {
        this.props.onSuccessCloseModal();
    }

    actionChange = () => {
        const { approveComment } = this.state;
        this.props.submit(this.setState({ approveComment }));
    }

    textAreaChange = (e) => {
        const { approveComment } = this.state;
        this.setState({ approveComment : e.target.value });
    }

    render() {
        let { approveComment } = this.state;
        const { open, requestObject, commentValue } = this.props;
        const finishBtnClass = ['btn btn-secondary yesBtn'];

		if (!commentValue) {
			finishBtnClass.push('disabled');
		} 
        const inlineStyle = { display: "none" };
        if (open) {
            inlineStyle.display = "block";
        }
        return (
            <div style={styles}>
            <Modal open={open} showCloseIcon={false} onClose={(e) => this.onCloseModal(e)}>
                <div className="modalBackdrop" style={inlineStyle}></div>
                <div className="modal fade confirmationYesNoModal show" data-keyboard="true" data-backdrop="static" style={inlineStyle}>
                    <div className="modal-dialog modal-md center">
                        <div className="modal-content">
                            <div className="modal-body">
                            <div className="confirmationYesNo">
                                <h5>Request</h5>
                                <textarea name="approveComment" className="form-control" placeholder="Please fill more information about your request." onChange={(e) => this.props.updateComment(e)} value={commentValue}></textarea>
                            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={() => this.props.onSuccessModel()}>Cancel</button>
                                <button className={finishBtnClass.join(' ')} data-dismiss="modal" type="button" value={approveComment} onClick={() => this.props.submit()}>{requestObject ? requestObject.charAt(0).toUpperCase() + requestObject.slice(1) : ""}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            </div>
        );
    }
}

export default ApprovalSubmitModal