const todaysDate = new Date();

const REQUEST_TYPE_CHANGE = "REQUEST_TYPE_CHANGE";
const DATE_CHANGE = "DATE_CHANGE";
const SORT_DATA = "SORT_DATA";
const PAGE_CHANGE = "PAGE_CHANGE";
const LOAD_DATA = "LOAD_DATA";
const SHOW_DETAIL = "SHOW_DETAIL";
const HIDE_DETAIL = "HIDE_DETAIL";
const ITEM_SELECT = "ITEM_SELECT";
const ITEM_SELECT_ALL = "ITEM_SELECT_ALL";
const SHOW_APPROVAL_MODEL = "SHOW_APPROVAL_MODEL"
const HIDE_APPROVAL_MODEL = "HIDE_APPROVAL_MODEL"
const SUBMIT_APPROVAL = "SUBMIT_APPROVAL"
const SHOW_PAGE_LOADER = "SHOW_PAGE_LOADER"


export const requestTypeChange = (payload) => ({type: REQUEST_TYPE_CHANGE, payload});
export const dateChange = (payload) =>  ({type: DATE_CHANGE, payload});
export const sortData = (payload) => ({type: SORT_DATA, payload});
export const pageChange = (payload) => ({type: PAGE_CHANGE, payload});
export const loadData = (payload) => ({type: LOAD_DATA, payload});
export const showDetail = (payload) => ({type: SHOW_DETAIL, payload});
export const hideDetail = () => ({type: HIDE_DETAIL});
export const itemSelect = (payload) => ({type: ITEM_SELECT, payload});
export const itemSelectAll = (payload) => ({type: ITEM_SELECT_ALL, payload});
export const showApprovalModel = (payload) => ({type: SHOW_APPROVAL_MODEL, payload});
export const hideApprovalModel = () => ({type:HIDE_APPROVAL_MODEL});
export const submitApproval = () => ({type:SUBMIT_APPROVAL});
export const showPageLoader = (payload) => ({type:SHOW_PAGE_LOADER, payload})


/*
List of request does not support  Refer back feature, if selected in the list 
Refer back button should be disabled
*/
const referBackNotFor = [
    "Activate Customer User",
    "Deactivate Customer User",
    "Add IOT Software",
    "Add Controller Software"
]


const approvalTypes = [
    {name:"approveRequest", value:"Approve"},
    {name:"rejectRequest", value:"Reject"},
    {name:"referBackRequest", value:"Refer Back"},
]

const isAllItemSelected = (items) => {
    const itemChecked = items.filter(item => item.checked);
    console.log("itemChecked", itemChecked);
    const approvalHasNoReferback = itemChecked.filter(item => referBackNotFor.includes(item.requestType.type));
    console.log("approvalHasNoReferback", approvalHasNoReferback)
    if(items.length === itemChecked.length){
        return {selectAll: 1, referAllowed: approvalHasNoReferback.length === 0};
    }else if(itemChecked.length > 0){
        return {selectAll: 0, referAllowed: approvalHasNoReferback.length === 0};
    }else{
        return {selectAll: -1, referAllowed: true};
    }
}

export const initialState = {
    sortColumn: { path: "date_created", order: "desc", sort_color: "#FFFFFF" },
    approvalList: [],
    startDate: "",
    endDate: "",
    minDate: new Date(todaysDate.setMonth(todaysDate.getMonth() - 12)),
    maxDate: new Date(),
    requestStatus: '',
    currentPage: 1,
    resetPage: false,
    pageSize: 25,
    totalPages: null,
    rowNumber:0,
    loading: true,
    showDetail: false,
    requestDetail: {},
    selectAll : -1,
    referAllowed : true,
    openApprovalModel : false,
    approvalType:{},
    pageLoader : false
  }


export const myApprovalReducer = (state, action) => {
    const {type, payload } = action;
    switch(type){
        case REQUEST_TYPE_CHANGE:
            return { ...state, requestStatus: payload, resetPage:true, loading : true}
        case DATE_CHANGE:
            return { ...state, [payload.field]: payload.value, loading : payload.loading , resetPage:true}
        case SORT_DATA:
            return { ...state, sortColumn: payload, loading : true }
        case PAGE_CHANGE:
            return { ...state, currentPage: payload, loading : true }
        case LOAD_DATA:
            return { ...state, ...payload, loading: false, resetPage: false, rowNumber : (payload.currentPage * state.pageSize) - state.pageSize }
        case SHOW_DETAIL:
            return { ...state, showDetail: true, requestDetail : payload }
        case HIDE_DETAIL:
            return { ...state, showDetail: false, requestDetail : {} }
        case ITEM_SELECT:
            return { ...state, approvalList: payload, ...isAllItemSelected(payload)}
        case SHOW_APPROVAL_MODEL:
            return { ...state, openApprovalModel: true, approvalType: approvalTypes.find(item => item.name==payload)}
        case HIDE_APPROVAL_MODEL:
            return { ...state, openApprovalModel: false , pageLoader: false}
        case SHOW_PAGE_LOADER:
            return { ...state, pageLoader: payload }
        case SUBMIT_APPROVAL:
            return { ...state, approvalType: {}, loading : true, pageLoader: false, openApprovalModel: false, selectAll: -1, referAllowed: true}
    }
}