import { GET_DATA, SET_DATA } from '../../actions/stateActionsData/type';

const initialState = {
    CustomerModel: {
        type: "",
        name: "",
        roleId: 0,
        roleName: "",
        email: "",
        retypeEmail: "",
        customerAdminName: "",
        groups:[{
            id : 0,
            name : ""
            }],
        phoneNumber: "",
        countryCode: "",
        countryCodeList: [],
        countryNameList: [],
        featureList: [],
        comment: "",
        streetName:"",
        streetNo:"",
        zipCode:"",
        cityName:"",
    }
}

export default function(state = initialState, action){
    switch(action.type) {
        case GET_DATA:
        return {
            ...state,
            CustomerModel: action.payload
        }
debugger;
        case SET_DATA:
        return {
            ...state,
            CustomerModel: action.payload
        }
        
        default:
        return state;
    }
}